const Footer = () => {
  return (
    <footer className="bg-primary text-white p-6">
      <div className="container mx-auto text-center">
        <div className="mb-6">
          <a href="/privacy-policy" className="p-2">
            Privacy Policy
          </a>
          <a href="/imprint" className="p-2">
            Imprint
          </a>
        </div>
        {/* <div className="mb-6">
          <a href="https://facebook.com" className="p-2">
            Facebook
          </a>
          <a href="https://twitter.com" className="p-2">
            Twitter
          </a>
          <a href="https://linkedin.com" className="p-2">
            LinkedIn
          </a>
        </div> */}
        <div>
          <p>
            &copy; {new Date().getFullYear()} Sponsearly. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
