// src/components/BackgroundPicture.js
import { useState, useEffect, FC } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebaseConfig"; // Adjust the path as necessary
import defaultBackgroundPic from "../../assets/default-background.png"; // Path to a default profile image

interface BackgroundPictureProps {
  userId: string;
}

const BackgroundPicture: FC<BackgroundPictureProps> = ({ userId }) => {
  const [backgroundPicUrl, setBackgroundPicUrl] = useState("");

  useEffect(() => {
    if (userId) {
      const backgroundPicRef = ref(storage, `backgroundPictures/${userId}`);

      getDownloadURL(backgroundPicRef)
        .then((url) => {
          setBackgroundPicUrl(url);
        })
        .catch(() => {
          setBackgroundPicUrl(defaultBackgroundPic);
        });
    }
  }, [userId]);

  if (backgroundPicUrl === "") {
    return null;
  } else {
    return (
      <div className="w-full overflow-hidden">
        <img
          src={backgroundPicUrl}
          alt="Background"
          className="w-full h-auto object-cover"
          style={{ maxHeight: "20rem" }} // Adjust maxHeight as needed
        />
      </div>
    );
  }
};

export default BackgroundPicture;
