import { useToast } from "src/context/ToastContext";
import PrimaryButton from "src/components/Buttons/PrimaryButton";
import { useAuth } from "src/context/AuthContext";
import { doc, increment, setDoc } from "firebase/firestore";
import { db } from "src/firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const NothingUp = () => {
  const { currentUser } = useAuth();
  const showToast = useToast();

  // Generate the invitation link
  const invitationLink = `${
    window.location.origin
  }/signup?inviter=${encodeURIComponent(
    currentUser?.displayName ?? "anonymous"
  ).replace(/%40/g, "@")}`;

  // Copy the invitation link to the clipboard
  const handleCopyInvitationLink = async () => {
    try {
      // Copy the invitation link to the clipboard
      await navigator.clipboard.writeText(invitationLink);
      showToast("Copied to clipboard", true);

      // Update the user's click count in 'referrerGenerateStatistics' collection
      // Use displayName as the document ID
      const userDocRef = doc(
        db,
        "referrerGenerateStatistics",
        currentUser?.displayName ?? "anonymous"
      );

      await setDoc(
        userDocRef,
        {
          displayName: currentUser?.displayName ?? "anonymous",
          count: increment(1),
        },
        { merge: true }
      );

      // Update the overall click count
      const overallDocRef = doc(db, "referrerGenerateStatistics", "overall");

      await setDoc(
        overallDocRef,
        {
          count: increment(1),
        },
        { merge: true }
      );
    } catch (err) {
      console.error("Error in handleCopyInvitationLink:", err);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center p-6 mx-auto max-w-6xl w-full ">
      {/* Add an engaging image at the top */}
      {/* <img
        src="/assets/images/community_growth.png"
        alt="Community Growth"
        className="w-full max-w-md mb-6"
      /> */}

      {/* Enhanced Headline */}
      <h2 className="text-4xl font-extrabold mb-4 text-primary">
        Help Us Grow Sponsearly!
      </h2>

      {/* Improved Typography */}
      <p className="text-lg mb-6 text-gray-700">
        We're excited to have you on board
        {currentUser?.displayName ? `, ${currentUser.displayName}` : ""}!
        Currently, there are no brands available because our community is still
        growing. The more streamers we have, the more attractive we become to
        brands. By inviting your streamer friends, you'll help us grow faster
        and bring brands to the platform sooner!
      </p>

      {/* Invitation Link Section */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-md w-full max-w-xl mb-6">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon
            icon={faUserPlus}
            className="text-primary text-2xl mr-2"
          />
          <p className="text-xl font-semibold">Your Invitation Link</p>
        </div>
        <p className="mb-4">
          Share this link with your streamer friends to invite them to
          Sponsearly.
        </p>
        <div className="flex items-center">
          <input
            type="text"
            value={invitationLink}
            readOnly
            className="flex-1 p-2 border border-gray-300 rounded-l-lg"
          />
          <button
            onClick={handleCopyInvitationLink}
            className="p-2 bg-primary text-white rounded-r-lg hover:bg-primary-dark transition-colors"
          >
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            Copy Link
          </button>
        </div>
      </div>

      {/* Call-to-Action Button */}
      <div className="flex flex-col items-center space-y-4">
        <PrimaryButton
          onClick={() => window.open("https://discord.gg/CHVqUhz9nS", "_blank")}
        >
          Join Our Discord Server
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NothingUp;
