// src/components/LandingPage.js
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { doc, setDoc, increment } from "firebase/firestore";
import FAQ from "./components/FAQ/FAQ";
import HeroSection from "./components/HeroSection/HeroSection";
import Features from "./components/Features/Features";
import ContactForm from "./components/ContactForm/ContactForm";
import WelcomeModal from "../../components/WelcomeModal/WelcomeModal";

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const siteParam = searchParams.get("site") || "default"; // Default to 'default' if 'site' is not provided

  useEffect(() => {
    const firstSiteParamVisited = localStorage.getItem("firstSiteParamVisited");

    if (!firstSiteParamVisited) {
      // First visit
      setIsModalOpen(true);
      localStorage.setItem("firstSiteParamVisited", siteParam);

      // Increment the count in Firestore
      const incrementSiteCount = async () => {
        try {
          // Format the date string
          const today = new Date();
          const dateString = `${today.getFullYear()}_${String(
            today.getMonth() + 1
          ).padStart(2, "0")}_${String(today.getDate()).padStart(2, "0")}`; // Format: YYYY_MM_DD

          // Reference to the document for the siteParam
          const siteDocRef = doc(db, "statistics", siteParam);

          // Update the count for the date field directly
          await setDoc(
            siteDocRef,
            {
              [dateString]: increment(1),
              totalCount: increment(1),
            },
            { merge: true }
          );

          // Update overall statistics
          const overallDocRef = doc(db, "statistics", "overall");

          await setDoc(
            overallDocRef,
            {
              [dateString]: increment(1),
              totalCount: increment(1),
            },
            { merge: true }
          );
        } catch (error) {
          console.error(
            `Error incrementing count for site ${siteParam}:`,
            error
          );
        }
      };

      incrementSiteCount();
    }
  }, [siteParam]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Fragment>
      <WelcomeModal isOpen={isModalOpen} onClose={closeModal} />

      <HeroSection />
      <div className="flex-grow ">
        <Features />
        <FAQ />
        <ContactForm />
      </div>
    </Fragment>
  );
};

export default LandingPage;
