import ContentPage from "src/components/ContentPage/ContentPage";
import ReferralRulesAccordion from "./ReferralRulesAccordion";
import PrimaryButton from "src/components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ReferralForUs = () => {
  const content = {
    headline: "Welcome to Sponsearly!",
    imageUrl: "/assets/images/referral.png", // Adjust the path as necessary
    smallImage: true,
    contentSections: [
      {
        headline: "We're Just Getting Started",
        text: "Thank you for joining Sponsearly! We're in the early stages of building a vibrant community of streamers. Currently, there are no brands available on the platform, but with your help, we can change that quickly!",
        imageUrl: "/assets/images/ringlight.png",
        smallImage: true,
      },
      {
        headline: "Help Us Grow the Community",
        text: "The more streamers we have on Sponsearly, the more attractive our platform becomes to brands. By inviting your streamer friends, you're directly contributing to bringing brands on board faster. Let's grow together!",
        imageUrl: "/assets/images/microphone.png",
        smallImage: true,
      },
      {
        headline: "Benefit Together and Earn Rewards",
        text: "By sharing your unique referral link, you not only help us grow but also increase your own chances of securing early sponsorships. Plus, you can earn points towards exciting prizes! Start spreading the word today.",
        imageUrl: "/assets/images/streamplifyhubdeck.png",
        smallImage: true,
      },
      {
        headline: "Join Our Discord Community",
        text: "Stay connected with us and other streamers by joining our Discord server. Get the latest updates, share your feedback, and be part of the conversation!",
        imageUrl: "/assets/images/connect-early.png",
        smallImage: true,
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div>
      <ContentPage
        headline={content.headline}
        imageUrl={content.imageUrl}
        smallImage={content.smallImage}
        contentSections={content.contentSections}
      />
      <div className="flex justify-center">
        <PrimaryButton onClick={() => navigate("/profile")}>
          Get Your Referral Link Now
        </PrimaryButton>
      </div>
      <ReferralRulesAccordion />
    </div>
  );
};

export default ReferralForUs;
