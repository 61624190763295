// import { useEffect, useState } from "react";
// import { db } from "../../firebaseConfig";
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   orderBy,
//   limit,
//   startAfter,
// } from "firebase/firestore";
// import LoadingSpinner from "../../components/Loading/LoadingSpinner";
// import PrimaryButton from "../../components/Buttons/PrimaryButton";
// import CreatorTable from "../Creators/CreatorSearch/Table/CreatorTable";
// import { IUser } from "../../interfaces/IUser";
// import { IMultiSelectElement } from "../../components/MultiSelect/MultiSelect";
// import { useLocation } from "react-router-dom";
import NothingUp from "./NothingUp";

// type SortType = {
//   field: keyof IUser | null;
//   direction: "asc" | "desc";
// };

const BrandSearch = () => {
  // const location = useLocation();
  // const initialFilter = location.state?.initialFilter;

  // const [brands, setBrands] = useState<IUser[]>([]);
  // const [loading, setLoading] = useState(true);
  // const [sort, setSort] = useState<SortType>({ field: null, direction: "asc" });
  // const [page, setPage] = useState(1);
  // const [filters, setFilters] = useState<{ [key in keyof IUser]?: string }>({});

  // const pageSize = 20;

  // const fetchCreators = async () => {
  //   setLoading(true);
  //   try {
  //     let q = query(
  //       collection(db, "users"),
  //       where("role", "==", "brand"),
  //       where("hasValidDisplayName", "==", true), // Ensure displayName exists and is not an empty string
  //       where("displayName", "!=", ""),
  //       ...(sort.field ? [orderBy(sort.field, sort.direction)] : []),
  //       limit(pageSize)
  //     );

  //     if (page > 1) {
  //       // Fetch the last document from the previous page to use as the starting point
  //       const previousPageEnd = query(
  //         collection(db, "users"),
  //         where("role", "==", "brand"),
  //         where("hasValidDisplayName", "==", true), // Ensure displayName exists and is not an empty string
  //         where("displayName", "!=", ""),
  //         ...(sort.field ? [orderBy(sort.field, sort.direction)] : []),
  //         limit((page - 1) * pageSize)
  //       );
  //       const documentSnapshots = await getDocs(previousPageEnd);
  //       const lastVisible =
  //         documentSnapshots.docs[documentSnapshots.docs.length - 1];

  //       // Adjust query to start after the last document from the previous page
  //       q = query(q, startAfter(lastVisible));
  //     }

  //     const querySnapshot = await getDocs(q);
  //     let fetchedCreators: IUser[] = [];
  //     querySnapshot.forEach((doc) => {
  //       fetchedCreators.push({ ...(doc.data() as IUser), id: doc.id });
  //     });

  //     // Apply frontend filtering if necessary
  //     fetchedCreators = fetchedCreators.filter((creator: IUser) =>
  //       Object.entries(filters).every(([key, value]) => {
  //         if (value === "") return true; // Skip filtering for this key if filter value is empty
  //         const typedKey = key as keyof IUser;
  //         const fieldValue = creator[typedKey];

  //         const keywords = value.toLowerCase().split(/\s+/).filter(Boolean);

  //         if (Array.isArray(fieldValue)) {
  //           // If the field value is an array, check if any label includes the filter value
  //           return fieldValue.some((item: IMultiSelectElement) =>
  //             keywords.some((keyword) =>
  //               item.label.toLowerCase().includes(keyword)
  //             )
  //           );
  //         } else if (
  //           typeof fieldValue === "string" ||
  //           fieldValue instanceof String
  //         ) {
  //           // If it's a string, just do a standard string comparison
  //           return keywords.some((keyword) =>
  //             fieldValue.toLowerCase().includes(keyword)
  //           );
  //         } else {
  //           // If it's neither an array nor a string, return false or handle as needed
  //           return false;
  //         }
  //       })
  //     );

  //     setBrands(fetchedCreators);
  //   } catch (error) {
  //     console.error("Error fetching creators:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleFilterChange = (field: keyof IUser, value: string) => {
  //   setFilters((prevFilters) => {
  //     // If the filter value is empty, delete the filter key
  //     if (value === "") {
  //       const newFilters = { ...prevFilters };
  //       delete newFilters[field];
  //       return newFilters;
  //     }
  //     // Otherwise, update the filter value
  //     return { ...prevFilters, [field]: value };
  //   });
  // };

  // useEffect(() => {
  //   fetchCreators(); // Fetch first page on initial load
  //   // eslint-disable-next-line
  // }, [sort, page, filters]);

  // useEffect(() => {
  //   if (initialFilter?.favoriteCategories) {
  //     // Assuming you want to filter by displayName for example
  //     setFilters({ favoriteCategories: initialFilter.favoriteCategories });
  //   } else if (initialFilter?.favoriteProducts) {
  //     setFilters({ favoriteProducts: initialFilter.favoriteProducts });
  //   }
  //   fetchCreators();
  //   // You should include fetchCreators in the dependency array only if it's guaranteed to be stable (not re-created on each render). If it's not stable, consider using useCallback or moving fetchCreators inside the effect.
  // }, [initialFilter]); // Add other dependencies as needed

  // const handleSort = (field: keyof IUser) => {
  //   setSort((prevSort) => ({
  //     field,
  //     direction:
  //       prevSort.field === field && prevSort.direction === "asc"
  //         ? "desc"
  //         : "asc",
  //   }));
  // };

  return (
    <div>
      <NothingUp />
      {/* <h2 className="font-bold ml-4 mt-4">
        Filter, sort and find brands you are interested in!
      </h2> */}
      {/* {loading && <LoadingSpinner />} */}
      {/* <CreatorTable
        isCreatorSearch={false}
        creators={brands}
        onSort={handleSort}
        onFilterChange={handleFilterChange}
        filters={filters}
        sort={sort}
      /> */}
      {/* <div className="flex justify-center mt-4">
        <PrimaryButton
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
        >
          Previous
        </PrimaryButton>
        <span className="mx-2">{page}</span>
        <PrimaryButton
          onClick={() => setPage((prev) => prev + 1)}
          disabled={brands.length < pageSize}
        >
          Next
        </PrimaryButton>
      </div> */}
    </div>
  );
};

export default BrandSearch;
