import { useState, useEffect, FC, useRef } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { db, realTimeDb } from "../../../firebaseConfig";
import { useAuth } from "../../../context/AuthContext";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { formatDateTimeString } from "../../../utils/dateUtils";
import {
  get,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";

interface Message {
  id: string;
  text: string;
  sender: string;
  timestamp: Date;
}

interface ChatRoomProps {
  chatPopUpId?: string;
}

const ChatRoom: FC<ChatRoomProps> = ({ chatPopUpId }) => {
  const { currentUser } = useAuth();
  const { chatId } = useParams();
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  // const [userNames, setUserNames] = useState<{ [uid: string]: string }>({});
  const navigate = useNavigate();
  const [contactName, setContactName] = useState("");
  const [contactId, setContactId] = useState("");
  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const chatIdToUse = chatPopUpId || chatId;

    const fetchChatDetails = async () => {
      if (!chatIdToUse || !currentUser) return;

      // Fetch chat details from Realtime Database
      const chatRefRealtimeDb = ref(realTimeDb, `chats/${chatIdToUse}`);
      const chatSnapshot = await get(chatRefRealtimeDb);

      if (chatSnapshot.exists()) {
        const chatData = chatSnapshot.val();
        const otherUserId = Object.keys(chatData.participants).find(
          (id) => id !== currentUser.uid
        );

        // Fetch other user's name from Firestore
        if (otherUserId) {
          const otherUserRef = doc(db, "users", otherUserId);
          const otherUserSnap = await getDoc(otherUserRef);
          if (otherUserSnap.exists()) {
            setContactId(otherUserId);
            setContactName(otherUserSnap.data().displayName || "Unknown");
          }
        }

        // Clear the unreadBy field if the current user is the one who has unread messages
        if (chatData.unreadBy === currentUser.uid) {
          update(chatRefRealtimeDb, { unreadBy: "" });
        }

        // Optionally, update userChats to reflect that there are no more unread messages in this chat
        const userChatRef = ref(
          realTimeDb,
          `userChats/${currentUser.uid}/${chatIdToUse}`
        );
        update(userChatRef, { unread: false });
      }

      // Subscribe to messages updates
      const messagesRef = ref(realTimeDb, `chats/${chatIdToUse}/messages`);
      onValue(query(messagesRef, orderByChild("timestamp")), (snapshot) => {
        const messagesData: any[] | ((prevState: Message[]) => Message[]) = [];
        snapshot.forEach((childSnapshot) => {
          messagesData.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        setMessages(messagesData);
      });
    };

    fetchChatDetails();
  }, [chatId, currentUser, chatPopUpId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Dependency array includes 'messages'

  const sendMessage = async () => {
    if (newMessage.trim() === "" || !currentUser || !contactId) return;

    const chatIdToUse = chatPopUpId || chatId;
    const timestamp = new Date().toISOString();

    // Add a new message to Realtime Database
    const newMessageRef = push(
      ref(realTimeDb, `chats/${chatIdToUse}/messages`)
    );
    set(newMessageRef, {
      text: newMessage,
      sender: currentUser.uid,
      timestamp: timestamp,
    });

    // Update the last message, timestamp, and unreadBy in the chat document
    const chatRef = ref(realTimeDb, `chats/${chatIdToUse}`);
    update(chatRef, {
      lastMessage: newMessage.slice(0, 50),
      lastMessageTimestamp: timestamp,
      unreadBy: contactId,
    });

    // Check if the recipient's userChats reference exists
    const recipientUserChatsRef = ref(
      realTimeDb,
      `userChats/${contactId}/${chatIdToUse}`
    );
    const recipientChatSnapshot = await get(recipientUserChatsRef);

    if (!recipientChatSnapshot.exists()) {
      // If it doesn't exist, create the reference
      set(recipientUserChatsRef, {
        id: chatIdToUse,
        // Include any other necessary properties for a new chat reference
      });
    }

    // Update the userChats node for the recipient to indicate an unread message
    update(recipientUserChatsRef, {
      unread: true,
      unreadTimestamp: timestamp, // Storing the timestamp when the message was sent
    });

    setNewMessage("");
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleDeleteChat = async () => {
    const chatIdToUse = chatPopUpId || chatId;
    if (!chatIdToUse || !currentUser) return;
    const currentUserChatRef = ref(
      realTimeDb,
      `userChats/${currentUser.uid}/${chatIdToUse}`
    );

    // Check if the other user still has the chat reference
    const otherUserChatRef = ref(
      realTimeDb,
      `userChats/${contactId}/${chatIdToUse}`
    );
    const otherUserChatSnapshot = await get(otherUserChatRef);

    if (!otherUserChatSnapshot.exists()) {
      // If the other user has already deleted their chat reference, delete the entire chat
      const chatRef = ref(realTimeDb, `chats/${chatIdToUse}`);
      await remove(chatRef);
    }

    // Delete chat from current user's userChats
    await remove(currentUserChatRef);

    // Redirect or update UI accordingly
    navigate("/chats");
  };

  return (
    <div
      style={
        !chatPopUpId ? { height: `calc(100vh - 6rem)` } : { height: `24rem ` }
        // calc(100vh - 22rem)` }
      }
      className="flex text-white flex-col"
    >
      <div className="flex items-center p-3 bg-primary">
        {!chatPopUpId && (
          <PrimaryButton onClick={() => navigate("/chats")}>Back</PrimaryButton>
        )}
        <div
          className={`flex items-center flex-grow ${
            !chatPopUpId ? "text-xl" : "text-m"
          }  ml-4 font-bold`}
        >
          <div className="flex justify-start mr-2">
            <ProfilePicture userId={contactId} size="3" />{" "}
          </div>
          <span>{contactName}</span>
          {/* Adjust size and add margin as needed */}
        </div>
        {!chatPopUpId && (
          <button
            onClick={handleDeleteChat}
            className="bg-transparent border-none"
          >
            <FontAwesomeIcon icon={faTrash} className="text-accent" />
          </button>
        )}
      </div>
      <div className="flex-grow overflow-y-auto p-3">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`p-3 my-2 rounded-lg max-w-xs ${
              message.sender === currentUser?.uid
                ? "bg-primary ml-auto"
                : "bg-primary mr-auto"
            }`}
          >
            <p>{message.text}</p>
            <span className="text-xs block text-right">
              {formatDateTimeString(message.timestamp.toString())}
            </span>
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Empty div for scrolling reference */}
      </div>
      <div className="flex p-3">
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          className="flex-grow p-2 border text-black border-gray-400 rounded mr-2"
          onKeyDown={handleKeyPress}
        />
        <button
          onClick={sendMessage}
          className="bg-primary text-white p-2 rounded"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatRoom;
