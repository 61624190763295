import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";

export const callTwitchExchangeFunction = async (code: any) => {
  const exchangeTwitchToken = httpsCallable(functions, "exchangeTwitchToken");

  try {
    const result = await exchangeTwitchToken({ code });

    return result.data;
  } catch (error) {
    console.error("Error calling Twitch exchange function:", error);
  }
};
