import { Fragment } from "react";
import ListItem from "../../../../components/StyleElements/ListItem";
import SectionTitle from "../../../../components/StyleElements/SectionTitle";

const content = [
  {
    title:
      "Connect Early with Leading Brands and Twitch Creators through Sponsearly",
    paragraph:
      "Sponsearly is your gateway to forming valuable partnerships at the start of your career. Our advanced algorithm efficiently connects brands with creators, ensuring you spend less time searching and more time building meaningful collaborations.",
    items: [
      "Dynamic Network of Twitch Creators and Brands: Stay ahead with a constantly updated and active network that keeps you connected with the latest and most relevant opportunities.",
      "Smart Matching Algorithm: We tailor our recommendations based on the unique needs of both brands and creators, ensuring the perfect fit for successful collaborations.",
      "Early Growth Advantage: Capitalize on high-engagement opportunities at an early stage, setting the foundation for sustained growth and influence.",
    ],
    image: "connect-early.png",
  },
  {
    title:
      "Skip the Hassle, Jump into Success: Early Sponsorships on Sponsearly",
    paragraph:
      "Be the first to spot emerging Twitch talents with Sponsearly. Our curated database and powerful matching algorithms provide you with a shortlist of eager and promising creators ready to amplify your brand.",
    items: [
      "Up-to-Date Creator Database: Our frequently updated database ensures that all creators are actively seeking partnerships, making it easier for you to connect with the right talent.",
      "Instant Communication: Use our direct communication channels to quickly connect with creators, facilitating smooth and prompt interactions.",
      "In-Depth Analytics: Monitor your shortlisted creators and track the success of your partnerships with detailed analytics, helping you make informed decisions.",
    ],
    image: "skip-the-search.png",
  },
  {
    title: "Find Your Perfect Brand Partnership on Sponsearly",
    paragraph:
      "Get noticed by leading brands and land your first sponsorship deal with Sponsearly. We provide a platform where brands can discover and support emerging Twitch creators, helping you turn your passion into a profession.",
    items: [
      "Global Brand Network: Gain access to top global brands looking for fresh talent to sponsor and promote.",
      "Free Sponsorship Opportunities: Start your journey with sponsored content, allowing you to grow your stream without financial stress.",
      "Direct Contact with Brand Managers: Connect with brand managers directly to discuss partnerships, giving you a professional edge in your content creation career.",
    ],
    image: "ideal-partnership.png",
  },
];

const Features = () => {
  return (
    <Fragment>
      <div className="mt-16">
        {content.map((el, index) => {
          const isEven = index % 2 === 0;

          return (
            <div
              key={index}
              className="mt-8 mx-auto flex justify-center max-w-6xl w-full items-center flex-col border-b border-body-color/[.15] pb-16 md:pb-20 lg:pb-28"
            >
              <div className="flex flex-wrap items-center">
                {/* Text Block */}
                <div
                  className={`w-full px-4 lg:w-1/2 ${
                    isEven ? "order-1 lg:order-2" : "order-1"
                  }`}
                >
                  <SectionTitle
                    title={el.title}
                    paragraph={el.paragraph}
                    mb="44px"
                  />

                  <div
                    className="wow fadeInUp mb-12 lg:mb-0"
                    data-wow-delay=".15s"
                  >
                    <div className="mx-[-12px] flex flex-wrap">
                      <div className="w-full px-3 sm:w-1/2 lg:w-full ">
                        {el.items.map((item) => {
                          return <ListItem key={item} text={item} />;
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Image Block */}
                <div
                  className={`w-full px-4 lg:w-1/2 ${
                    isEven ? "order-2 lg:order-1" : "order-2"
                  }`}
                >
                  <div
                    className="wow fadeInUp relative mx-auto aspect-[25/24] max-w-[500px] lg:mr-0"
                    data-wow-delay=".2s"
                  >
                    <img
                      src={
                        el?.image
                          ? `/assets/images/${el.image}`
                          : "/assets/images/404.png"
                      }
                      alt={el.title}
                      className="w-full h-auto mb-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Features;
