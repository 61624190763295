import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { FC } from "react";

interface PrivateRouteProps {
  children: JSX.Element;
  brandRoute?: boolean;
  creatorRoute?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  children,
  brandRoute = false,
  creatorRoute = false,
}) => {
  const { currentUser, isAuthInitialized, isPremium, userRole } = useAuth();
  const location = useLocation();

  if (!isAuthInitialized) {
    return <LoadingSpinner />; // Show loading spinner while waiting for auth initialization
  }
  // Check if user is not logged in
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // making it idiot user proof
  if (!currentUser.displayName && userRole === "brand") {
    return <Navigate to="/brand-profile-creation" replace />;
  }

  if (userRole === "brand" && creatorRoute) {
    return <Navigate to="/" replace />;
  }

  if ((userRole === "creator" && brandRoute) || (!userRole && brandRoute)) {
    return <Navigate to="/" replace />;
  }

  // Check if user is a non-premium brand
  if (userRole === "brand" && !isPremium) {
    return <Navigate to="/account" replace />;
  }

  // Allow access if user is not a brand, or is a premium brand
  return userRole !== "brand" || isPremium ? children : null;
};

export default PrivateRoute;
