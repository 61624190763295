import { FC, useState, useEffect } from "react";

export interface IToastProps {
  text: string;
  isSuccess: boolean;
}

const Toast: FC<IToastProps> = ({ text, isSuccess }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!text) {
      setMounted(false);
    }
  }, [text]);

  useEffect(() => {
    if (text) {
      setTimeout(() => setMounted(true), 100); // small delay before triggering the animation
    }
  }, [text]);

  return (
    <div
      className={`fixed left-1/2 top-1/4 z-50 max-w-md -translate-x-1/2 transform rounded-md ${
        isSuccess ? "bg-primary" : "bg-accent"
      }  px-6 py-3 text-lg  text-white shadow-2xl transition-all duration-500 ease-out 
				${mounted ? "translate-y-40 opacity-100" : "-translate-y-full opacity-0"}`}
    >
      {text}
    </div>
  );
};

export default Toast;
