import ContentPage from "src/components/ContentPage/ContentPage";
import ReferralRulesAccordion from "./ReferralRulesAccordion";
import PrimaryButton from "src/components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const Referral = () => {
  const content = {
    headline: "Referral System at Sponsearly",
    imageUrl: "/assets/images/referral.png", // Adjust the path as necessary
    smallImage: true,
    contentSections: [
      {
        headline: "Invite Your Streamer Friends",
        text: "At Sponsearly, we believe in the power of community. Share your unique invitation link with your streamer friends and help them discover the benefits of early brand partnerships. It's easy and rewarding!",
        imageUrl: "/assets/images/ringlight.png",
        smallImage: true,
      },
      {
        headline: "Earn Points with Each Signup",
        text: "Every time a friend signs up using your link and authenticates their Twitch profile, you earn points. The more friends you invite, the more points you accumulate. Watch your points soar as your friends join the Sponsearly community!",
        imageUrl: "/assets/images/microphone.png",
        smallImage: true,
      },
      {
        headline: "Win Exciting Monthly Prizes",
        text: "Your points boost your chances of winning fantastic prizes! Each month, we reward our top point earners with exclusive rewards. The more points you have, the better your odds of winning. Start sharing your invitation link today and let's grow the Sponsearly community together!",
        imageUrl: "/assets/images/streamplifyhubdeck.png",
        smallImage: true,
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div>
      <ContentPage
        headline={content.headline}
        imageUrl={content.imageUrl}
        smallImage={content.smallImage}
        contentSections={content.contentSections}
      />
      <div className="flex justify-center">
        <PrimaryButton onClick={() => navigate("/profile")}>
          Get Your Referral Link Now
        </PrimaryButton>
      </div>
      <ReferralRulesAccordion />
    </div>
  );
};

export default Referral;
