import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebaseConfig";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../interfaces/IUser";
import MultiSelect from "../../../components/MultiSelect/MultiSelect";
import { categoryOptions } from "../../../utils/selectOptions/categoryOptions";
import { groupedProductOptions } from "../../../utils/selectOptions/productOptions";
import { TOASTMESSAGES, useToast } from "../../../context/ToastContext";
import { useAuth } from "../../../context/AuthContext";
import { audienceOptions } from "../../../utils/selectOptions/regionOptions";

const BrandProfileCreation = () => {
  const [user, setUser] = useState<IUser>({
    displayName: "",
    email: "",
    companyName: "",
    address: {
      firstName: "",
      surname: "",
      street: "",
      postalCode: "",
      city: "",
      country: "",
      state: "",
    },
    contactChannel: "",
    typeOfContent: "",
    audience: [],
    twitchName: "",
    favoriteProducts: [],
    favoriteCategories: [],
    role: "brand",
    hasValidDisplayName: true,
    // Add other user fields as needed
  });
  const showToast = useToast();

  const navigate = useNavigate();

  const { updateUserRole } = useAuth(); // Destructure the new method

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        try {
          const userRef = doc(db, "users", auth.currentUser.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data() as IUser;
            setUser({
              ...userData,
              displayName: userData?.displayName || "",
              email: userData?.email || "",
              companyName: userData?.companyName || "", // Ensure this is not undefined
              address: {
                firstName: userData.address?.firstName || "",
                surname: userData.address?.surname || "",
                street: userData.address?.street || "",
                postalCode: userData.address?.postalCode || "",
                city: userData.address?.city || "",
                country: userData.address?.country || "",
                state: userData.address?.state || "",
              },
              contactChannel: userData?.contactChannel || "",
              typeOfContent: userData?.typeOfContent || "",
              audience: userData?.audience || [],
              twitchName: userData?.twitchName || "",
              favoriteProducts: userData?.favoriteProducts || [],
              favoriteCategories: userData?.favoriteCategories || [],
              role: userData?.role || "brand",
            });
          } else {
            console.log("User document does not exist.");
            // Handle the case where the user document doesn't exist if needed
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const updateUserData = async () => {
    if (auth.currentUser) {
      try {
        const userProfile = user as { [x: string]: any }; // Type assertion

        // Update displayName in Firebase Authentication
        if (user.displayName && user.displayName.length > 0) {
          await updateProfile(auth.currentUser, {
            displayName: user.displayName,
          });
          setUser({ ...user, hasValidDisplayName: true });
        }

        // Update user profile in Firestore
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, userProfile);
        // alert("Profile Updated Successfully!");
        showToast(TOASTMESSAGES.SUCCESS, true);

        if (userProfile.role) {
          updateUserRole(userProfile.role);
        }

        navigate("/how-it-works-brand");
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const isFormValid =
    user.displayName &&
    user.contactChannel &&
    user.companyName &&
    user.audience &&
    user.address.firstName &&
    user.address.surname &&
    user.address.city &&
    user.address.country &&
    user.address.postalCode &&
    user.address.street &&
    user.favoriteCategories.length > 0 &&
    user.favoriteProducts.length > 0;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Create Your Profile</h2>
      <p className="mb-4">
        We need some crucial information so you can find sponsors. Please take
        your time to create your account.
      </p>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          {/* <label htmlFor="twitchName" className="block text-sm font-bold mb-2">
            Twitch Name:
          </label>
          <input
            type="text"
            id="twitchName"
            placeholder="Twitch Name"
            value={user.twitchName}
            onChange={(e) => setUser({ ...user, twitchName: e.target.value })}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          /> */}
          Pick a username which will be displayed within the application.
          <label htmlFor="displayName" className="block text-sm font-bold mb-2">
            Username:
          </label>
          <input
            type="text"
            id="displayName"
            placeholder="Brand Name"
            value={user.displayName}
            onChange={(e) => setUser({ ...user, displayName: e.target.value })}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          Please specify the name of the company.
          <label htmlFor="companyName" className="block text-sm font-bold mb-2">
            Company:
          </label>
          <input
            type="text"
            id="companyName"
            placeholder="Company"
            value={user.companyName}
            onChange={(e) => setUser({ ...user, companyName: e.target.value })}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          Please let us know how we should contact you.
          <label
            htmlFor="contactChannel"
            className="block text-sm font-bold mb-2"
          >
            Contact Channel: *
          </label>
          <input
            type="text"
            id="contactChannel"
            placeholder="Enter your email, phone, WhatsApp, etc."
            value={user.contactChannel}
            onChange={(e) =>
              setUser({ ...user, contactChannel: e.target.value })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          Please specify in which region you are active.{" "}
          <label htmlFor="audience" className="block text-sm font-bold mb-2">
            Audience: *
          </label>
          {/* <input
            type="text"
            id="audience"
            placeholder="Germany, USA, Korea, etc."
            value={user.audience}
            onChange={(e) => setUser({ ...user, audience: e.target.value })}
            className="w-full px-3 py-2 border rounded text-secondaryText"
          /> */}
          <MultiSelect
            elements={audienceOptions}
            selectedElements={user.audience}
            setSelectedElements={(elements) =>
              setUser({ ...user, audience: elements })
            }
          />
        </div>
      </div>
      Specify 1 - 5 favorite product categories and 1 - 5 favorite products you
      are interested in, so others can find you easily.
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            htmlFor="product-select"
            className="block text-sm font-bold mb-2"
          >
            Categories:
          </label>
          <MultiSelect
            elements={categoryOptions}
            selectedElements={user.favoriteCategories}
            setSelectedElements={(elements) =>
              setUser({ ...user, favoriteCategories: elements })
            }
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            htmlFor="product-select"
            className="block text-sm font-bold mb-2"
          >
            Products:
          </label>
          <MultiSelect
            elements={groupedProductOptions}
            selectedElements={user.favoriteProducts}
            setSelectedElements={(elements) =>
              setUser({ ...user, favoriteProducts: elements })
            }
          />
        </div>
      </div>
      <hr className="my-4 border-t-2 border-primary w-full rounded-lg shadow-lg" />
      Please provide some more company details.
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="firstName" className="block text-sm font-bold mb-2">
            First Name:
          </label>
          <input
            type="text"
            id="firstName"
            placeholder="First Name"
            value={user.address.firstName}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, firstName: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="surname" className="block text-sm font-bold mb-2">
            Surname:
          </label>
          <input
            type="text"
            id="surname"
            placeholder="Surname"
            value={user.address.surname}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, surname: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          {" "}
          <label htmlFor="street" className="block text-sm font-bold mb-2">
            Company Street:
          </label>
          <input
            type="text"
            id="street"
            placeholder="Street"
            value={user.address.street}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, street: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          {" "}
          <label htmlFor="postalCode" className="block text-sm font-bold mb-2">
            Company Postalcode:
          </label>
          <input
            type="text"
            id="postalCode"
            placeholder="Postalcode"
            value={user.address.postalCode}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, postalCode: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="city" className="block text-sm font-bold mb-2">
            Company City:
          </label>
          <input
            type="text"
            id="city"
            placeholder="City"
            value={user.address.city}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, city: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-4">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          {" "}
          <label htmlFor="country" className="block text-sm font-bold mb-2">
            Company Country:
          </label>
          <input
            type="text"
            id="country"
            placeholder="Country"
            value={user.address.country}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, country: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>

        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label htmlFor="state" className="block text-sm font-bold mb-2">
            Company State:
          </label>
          <input
            type="text"
            id="state"
            placeholder="State"
            value={user.address.state}
            onChange={(e) =>
              setUser({
                ...user,
                address: { ...user.address, state: e.target.value },
              })
            }
            className="w-full px-3 py-2 border rounded text-secondaryText"
          />
        </div>
      </div>
      <PrimaryButton onClick={updateUserData} disabled={!isFormValid}>
        Confirm
      </PrimaryButton>
    </div>
  );
};

export default BrandProfileCreation;
