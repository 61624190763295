import Articles from "./Articles";
import UploadArticle from "./UploadArticle";

import { useAuth } from "../../context/AuthContext";

const Blog = () => {
  const { userRole } = useAuth();

  return (
    <div className="container mx-auto">
      <Articles />
      {userRole === "author" && <UploadArticle />}
    </div>
  );
};
export default Blog;
