import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebaseConfig";

interface ILink {
  url: string;
  displayText: string;
}

interface IParagraph {
  text: string;
  links: ILink[];
}

interface IArticle {
  id: string;
  title: string;
  content: IParagraph[];
  url: string;
}

const Article = () => {
  const { articleId } = useParams<{ articleId: string }>(); // Ensure correct typing for useParams
  const [article, setArticle] = useState<IArticle | undefined>();

  useEffect(() => {
    const fetchArticle = async () => {
      const articlesRef = collection(db, "articles");
      const q = query(articlesRef, where("url", "==", "/" + articleId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assuming there's only one article with this URL
        const docSnap = querySnapshot.docs[0];
        setArticle({ id: docSnap.id, ...docSnap.data() } as IArticle);
      } else {
        console.log("No such article!", articleId);
      }
    };

    if (articleId) {
      fetchArticle();
    }
  }, [articleId]);

  const renderParagraph = (paragraph: IParagraph, index: number) => {
    const parsedParts = parseParagraph(paragraph.text);
    return (
      <p key={index} className="mb-4">
        {parsedParts.map((part, index) =>
          part.url ? (
            <a
              key={index}
              href={part.url}
              className="text-blue-500 hover:text-blue-700 underline"
            >
              {part.text}
            </a>
          ) : (
            <span key={index}>{part.text}</span>
          )
        )}
      </p>
    );
  };

  const parseParagraph = (text: string) => {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let match;
    let lastIndex = 0;
    let parts: { text: string; url?: string }[] = [];

    while ((match = linkRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push({ text: text.substring(lastIndex, match.index) });
      }

      parts.push({ text: match[1], url: "/blog" + match[2] });

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < text.length) {
      parts.push({ text: text.substring(lastIndex) });
    }

    return parts;
  };
  // You can now use articleId to fetch or display the specific article
  return (
    <div className="max-w-4xl mx-auto p-5">
      <h1 className="text-3xl font-bold mb-6">{article?.title}</h1>
      {article?.content.map((para, index) => renderParagraph(para, index))}
    </div>
  );
};

export default Article;
