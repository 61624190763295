import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { getPremiumStatus } from "../modules/Payment/getPremiumStatus";
import { doc, getDoc } from "firebase/firestore";

// Define the shape of your context
interface AuthContextType {
  currentUser: User | null;
  isAuthInitialized: boolean;
  isPremium: boolean;
  userRole: "creator" | "brand" | "author" |null;
  updateUserRole: (role: "creator" | "brand" | "author" | null) => void; // Add this line
}

// Create context with a default value
const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isAuthInitialized: false,
  isPremium: false,
  userRole: null,
  updateUserRole: () => {}, // Add a no-op function as a default
});

// Use this hook to access the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

interface IAuthproviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<IAuthproviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [isPremium, setIsPremium] = useState(false);
  const [userRole, setUserRole] = useState<"creator" | "brand" |"author" | null>(null);

  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     setCurrentUser(user);
  //     setIsAuthInitialized(true); // Set to true once we know the user's auth status
  //   });

  //   return unsubscribe;
  // }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setIsAuthInitialized(true);

      if (user) {
        try {
          // Fetch premium status
          const premiumStatus = await getPremiumStatus();
          setIsPremium(premiumStatus);

          // Fetch user role
          const userRef = doc(db, "users", user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            setUserRole(userData.role); // Assuming 'role' is the field name in your Firestore
          }
        } catch (error) {
          console.error("Error fetching additional user data:", error);
        }
      } else {
        // Reset state if user is not logged in
        setIsPremium(false);
        setUserRole(null);
      }
    });

    return unsubscribe;
  }, []);

  const updateUserRole = (role: "creator" | "brand" | "author" | null) => {
    setUserRole(role);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthInitialized,
        isPremium,
        userRole,
        updateUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
