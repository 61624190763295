// src/components/NavDropdown.js
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface NavDropdownProps {
  title: string;
  items: { title: string; route: string }[];
  closeMenu: () => void;
}

const NavDropdown: React.FC<NavDropdownProps> = ({
  title,
  items,
  closeMenu,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={(e) => {
          // e.stopPropagation(); // Prevent click from propagating to document
          setShowDropdown(!showDropdown);
        }}
        className="p-2"
      >
        {" "}
        {title}{" "}
        {!showDropdown ? (
          <FontAwesomeIcon icon={faChevronDown} />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} />
        )}
      </button>
      {showDropdown && (
        // <div className="absolute z-10 mt-2 bg-primary  rounded shadow-xl">
        <div
          className={`md:absolute z-10 mt-2 rounded md:shadow-xl shadow-none md:bg-backgroundPrimary ${
            !showDropdown ? "hidden" : ""
          }`}
        >
          {items.map((item) => (
            <Link
              key={item.title}
              to={item.route}
              className="block px-4 py-2 text-sm rounded md:hover:bg-backgroundSecondary"
              // className="block px-4 py-2 text-sm rounded hover:bg-primaryHover"
              onClick={() => {
                setShowDropdown(false);
                closeMenu();
              }}
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavDropdown;
