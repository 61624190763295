import { useCallback, useEffect, useState } from "react";
import { functions } from "../../../firebaseConfig";
import CreatorTable from "./Table/CreatorTable";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { IUser } from "../../../interfaces/IUser";
import { useLocation } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationNext,
} from "src/components/shadcn/Pagination";
import { IMultiSelectElement } from "src/components/MultiSelect/MultiSelect";
import { httpsCallable } from "firebase/functions";
import { RadioGroup, RadioGroupItem } from "src/components/shadcn/RadioGroup";

export type SortType = {
  field: "followers_total" | "followers_change" | null;
  direction: "asc" | "desc";
};

export interface RangeFilter {
  min: string;
  max: string;
}

export type FilterValue = string | RangeFilter | IMultiSelectElement[];

export interface FetchCreatorsResult {
  creators: IUser[];
  hasMore: boolean;
  lastDoc: any[] | null; // Updated to include lastDoc
}

export interface Filters {
  displayName: string;
  audience: IMultiSelectElement[];
  favoriteCategories: IMultiSelectElement[];
  favoriteProducts: IMultiSelectElement[];
  followers_total: RangeFilter;
  followers_change: RangeFilter;
}

// Initialize all possible filters with default values
const initialFilters: Filters = {
  displayName: "",
  audience: [],
  favoriteCategories: [],
  favoriteProducts: [],
  followers_total: { min: "", max: "" },
  followers_change: { min: "", max: "" },
};

const CreatorSearch = () => {
  const location = useLocation();
  const initialFilter = location.state?.initialFilter;

  const [creators, setCreators] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState<SortType>({
    field: "followers_change",
    direction: "desc",
  });
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [error, setError] = useState<string | null>(null);
  const [hasMoreData, setHasMoreData] = useState(true);

  const [prefetchedCreators, setPrefetchedCreators] = useState<IUser[]>([]);
  const [lastDoc, setLastDoc] = useState<any[] | null>(null); // Changed to accommodate array of values

  const [shouldFetch, setShouldFetch] = useState(false);

  const pageSize = 20;
  const batchSize = 100;

  // handleFilterChange now accepts string or IMultiSelectElement[]
  const handleFilterChange = (
    field: keyof Filters,
    value: string | IMultiSelectElement[] | RangeFilter,
    rangePart?: "min" | "max"
  ) => {
    setFilters((prevFilters) => {
      let updatedFilters: Filters = { ...prevFilters };

      // Handle MultiSelect fields
      if (
        field === "favoriteCategories" ||
        field === "favoriteProducts" ||
        field === "audience"
      ) {
        updatedFilters[field] = value as IMultiSelectElement[];
      }
      // Handle Range fields (followers_total and followers_change)
      else if (field === "followers_total" || field === "followers_change") {
        const currentFilter = prevFilters[field] as RangeFilter;
        updatedFilters[field] = {
          ...currentFilter,
          [rangePart!]: (value as string).trim(),
        };
      }
      // Handle string fields
      else if (field === "displayName") {
        updatedFilters[field] = (value as string).trim();
      }
      return updatedFilters;
    });
  };

  // New function to handle sort option change
  const handleSortChange = (field: "followers_total" | "followers_change") => {
    setSort({
      field,
      direction: "desc", // Default direction
    });

    // Do not fetch data here
  };

  const fetchCreators = useCallback(async () => {
    setLoading(true);
    setError(null);

    console.log("fetchCreators started");

    try {
      const data = {
        filters,
        sort,
        batchSize,
        startAfterValues: lastDoc,
      };

      const fetchCreatorsFunction = httpsCallable<unknown, FetchCreatorsResult>(
        functions,
        "fetchCreators"
      );

      const result = await fetchCreatorsFunction(data);

      const {
        creators: fetchedCreators,
        hasMore,
        lastDoc: newLastDoc,
      } = result.data;

      setPrefetchedCreators((prev) => [...prev, ...fetchedCreators]);

      console.log("creators", result.data, fetchedCreators.length);

      if (fetchedCreators.length > 0) {
        setLastDoc(newLastDoc);
      }

      setHasMoreData(hasMore);
    } catch (error: any) {
      console.error("Error fetching creators:", error);
      setError(error.message || "Failed to load creators. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [filters, sort, lastDoc]); // Include filters, sort, and lastDoc as dependencies

  useEffect(() => {
    // Fetch initial data on component mount
    // Reset pagination and data
    setPage(1); // Reset to first page
    setPrefetchedCreators([]); // Reset prefetched data
    setLastDoc(null); // Reset last document
    setHasMoreData(true); // Reset hasMoreData

    fetchCreators(); // Fetch data with default filters and sort
  }, []); // Empty dependency array ensures this runs once on mount

  // Update creators displayed based on page
  useEffect(() => {
    // If there are no prefetched creators yet, wait for the initial fetch
    if (prefetchedCreators.length === 0) {
      return;
    }

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedCreators = prefetchedCreators.slice(startIndex, endIndex);
    setCreators(paginatedCreators);

    // If we reach the end of prefetched data and there might be more data, fetch more
    if (endIndex >= prefetchedCreators.length && hasMoreData && !loading) {
      fetchCreators();
    }
  }, [page, prefetchedCreators]);

  // Handle initial filter from location state
  useEffect(() => {
    if (initialFilter) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...initialFilter,
      }));
      setPage(1); // Reset to first page
      setPrefetchedCreators([]); // Reset prefetched data
      setLastDoc(null); // Reset last document
      setHasMoreData(true); // Reset hasMoreData
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilter]);

  // Define pagination handlers
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Function to handle Apply Filters button click
  const handleApplyFilters = () => {
    // Reset pagination and data
    setPage(1); // Reset to first page
    setPrefetchedCreators([]); // Reset prefetched data
    setLastDoc(null); // Reset last document
    setHasMoreData(true); // Reset hasMoreData

    setShouldFetch(true); // Set shouldFetch to true to trigger useEffect
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchCreators();
      setShouldFetch(false); // Reset shouldFetch
    }
  }, [shouldFetch, fetchCreators]);

  // Function to handle Erase Filters button click
  const handleEraseFilters = () => {
    setFilters(initialFilters);
    setSort({
      field: "followers_change",
      direction: "desc",
    });
    setPage(1); // Reset to first page
    setPrefetchedCreators([]); // Reset prefetched data
    setLastDoc(null); // Reset last document
    setHasMoreData(true); // Reset hasMoreData

    fetchCreators(); // Fetch data with initial filters and sort
  };

  return (
    <div>
      <h2 className="font-bold ml-4 mt-4">
        Filter, sort and find creators you are interested in!
      </h2>
      {loading && <LoadingSpinner />}
      {error && (
        <div className="text-center text-red-500 mt-4">
          {error}
          <button
            onClick={() => fetchCreators()}
            className="mt-2 px-4 py-2 bg-red-600 text-white rounded"
          >
            Retry
          </button>
        </div>
      )}
      {!loading && creators.length === 0 && !error && (
        <div className="text-center text-gray-500 mt-4">
          No creators found matching your criteria.
        </div>
      )}
      <div className="flex justify-end items-center gap-2 mt-4">
        {/* Sorting Radio Buttons */}
        <RadioGroup
          value={sort.field ?? "followers_change"}
          onValueChange={(value) =>
            handleSortChange(value as "followers_total" | "followers_change")
          }
          className="flex items-center space-x-4"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="followers_change" id="followers_change" />
            <label htmlFor="followers_change" className="text-sm font-medium">
              Followers Gained in Week
            </label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="followers_total" id="followers_total" />
            <label htmlFor="followers_total" className="text-sm font-medium">
              Total Followers
            </label>
          </div>
        </RadioGroup>

        <div className="flex space-x-2">
          <button
            onClick={handleApplyFilters}
            className="px-4 py-2 bg-primary text-white rounded"
          >
            Apply Filters
          </button>
          <button
            onClick={handleEraseFilters}
            className="px-4 py-2 bg-secondary text-white rounded"
          >
            Erase Filters
          </button>
        </div>
      </div>
      <CreatorTable
        isCreatorSearch={true}
        creators={creators}
        // onSort={handleSort}
        onFilterChange={handleFilterChange}
        filters={filters}
        // sort={sort}
      />
      <div className="flex justify-center mt-4">
        {/* Pagination Section */}
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={handlePreviousPage}
                disabled={page === 1}
                aria-label="Previous Page"
              />
            </PaginationItem>
            {/* Display current page */}
            <PaginationItem>
              <span className="px-2 py-1">Page {page}</span>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={handleNextPage}
                disabled={
                  !hasMoreData && page * pageSize >= prefetchedCreators.length
                }
                aria-label="Next Page"
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
};

export default CreatorSearch;
