// SomeContentSite.js
import { Fragment } from "react";
import ContentPage from "../../../components/ContentPage/ContentPage";
import Payment from "../../Payment/Payment";
import { useAuth } from "../../../context/AuthContext";

const HowItWorksBrand = () => {
  const { isPremium } = useAuth();
  const content = {
    headline: "This is how it works as brand",
    imageUrl: "/assets/images/background-how-it-works.png", // Adjust the path as necessary
    contentSections: [
      {
        headline: "",
        text: "We help you to identify high potential / fast growing talents and creators early on in their career which explicitly express interest and openness to cooperate with your Brand. Our interest-based matching for Brands and Creators provides much shorter acquisition times and a significantly higher success rate for outbound messages to talents.",
        imageUrl: "/assets/images/how-it-works-1.png",
      },
      {
        headline: "",
        text: "When listing your brand on Sponsearly make sure to focus on products and categories for which you actively are looking for new promotional partners. We use the same set of products and categories for the creators - this way we ensure you only get those talents suggested, that are open and interested in a cooperation with your Brand.",
        imageUrl: "/assets/images/how-it-works-2.png",
      },
      {
        headline: "",
        text: "On the Creators page you can find and filter your next potential cooperation partners. We suggest starting with product categories and going into actual products later on. You will be able to instantly see the current followers on Twitch as well as the gain in the last week. We help you to set minimum or maximum values to fasten up your search. When you want to know more about a Creator, you can find following information on their profile page: \n- Hours Streamed \n- Hours Watched \n- Average Viewers \n- Peak Viewers \n- Total Followers \n- Followers Gained ",
        imageUrl: "/assets/images/how-it-works-3.png",
      },
      {
        headline: "",
        text: "You can find even more info on Twitchtracker.com (link is in profile) but we believe, above indicators are sufficient for shortlisting and filtering. ",
        imageUrl: "/assets/images/how-it-works-4.png",
      },
      {
        headline: "",
        text: "After identifying a creator that matches your criteria: \n- Interested in your Product / Product Category \n- High Growth in Followers / Viewers \n- Consistent or growing Stream time \nyou can directly message them via the Sponsearly-Chat function. We know how often general emails get lost in inboxes / spam or just get ignored. Skip the email inbox. On Sponsearly every Creator has only signed up for one reason - to get in touch with you and your brand.",
        imageUrl: "/assets/images/how-it-works-5.png",
      },
    ],
  };

  return (
    <Fragment>
      <ContentPage {...content}>
        <div className="flex justify-center align-middle m-10">
          {!isPremium && <Payment />}
        </div>
      </ContentPage>
      <div className="flex justify-center align-middle m-20">
        {!isPremium && <Payment />}
      </div>
    </Fragment>
  );
};

export default HowItWorksBrand;
