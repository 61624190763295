import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";

interface ILink {
  url: string;
  displayText: string;
}

interface IParagraph {
  text: string;
  links: ILink[];
}

interface IArticle {
  id: string;
  title: string;
  content: IParagraph[];
  url: string;
}

const Articles = () => {
  const [articles, setArticles] = useState<IArticle[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      const querySnapshot = await getDocs(collection(db, "articles"));
      const articlesArray = querySnapshot.docs.map((doc) => ({
        // id: doc.id,
        ...(doc.data() as IArticle), // Cast to IArticle for proper typing
      }));
      setArticles(articlesArray);
    };

    fetchArticles();
  }, []);

  const openArticle = (articleId: string) => {
    navigate(`/blog${articleId}`);
  };

  return (
    <div className="space-y-2 mb-4 px-3">
      {articles.map((article) => (
        <div
          key={article.url}
          className={"text-primary cursor-pointer"}
          onClick={() => openArticle(article.url)}
        >
          {article.title} {article.url}
        </div>
      ))}
    </div>
  );
};

export default Articles;
