import { FC, RefObject } from "react";
import BackgroundPicture from "../BackgroundPicture/BackgroundPicture";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import PrimaryButton from "../Buttons/PrimaryButton"; // Adjust import path as necessary

interface ProfileBannerDisplayProps {
  userId: string;
  onProfileImageSelect?: (event: any) => void;
  onBackgroundImageSelect?: (event: any) => void;
  profileInputRef?: RefObject<HTMLInputElement>;
  backgroundInputRef?: RefObject<HTMLInputElement>;
  isEditView?: boolean;
}

const ProfileBannerDisplay: FC<ProfileBannerDisplayProps> = ({
  userId,
  onProfileImageSelect,
  onBackgroundImageSelect,
  profileInputRef,
  backgroundInputRef,
  isEditView = false,
}) => {
  return (
    <div className="relative mb-4">
      {/* Background Picture */}
      <BackgroundPicture userId={userId} />

      {/* Profile Picture Overlay */}
      <div className="absolute bottom-2 left-0 ml-4">
        <ProfilePicture userId={userId} />
      </div>

      {isEditView && (
        <div className="absolute bottom-2 right-0 mr-4 flex flex-col items-end space-y-3">
          {/* Change Background Picture Button */}
          <div>
            <input
              type="file"
              id="backgroundPicture"
              accept="image/*"
              onChange={onBackgroundImageSelect}
              style={{ display: "none" }}
              ref={backgroundInputRef}
            />
            <PrimaryButton onClick={() => backgroundInputRef?.current?.click()}>
              Change Background Picture
            </PrimaryButton>
          </div>

          {/* Change Profile Picture Button, positioned over the background picture */}
          <div className="">
            {" "}
            {/* Adjust the negative margin as needed */}
            <input
              type="file"
              id="profilePicture"
              accept="image/*"
              onChange={onProfileImageSelect}
              style={{ display: "none" }}
              ref={profileInputRef}
            />
            <PrimaryButton onClick={() => profileInputRef?.current?.click()}>
              Change Profile Picture
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileBannerDisplay;
