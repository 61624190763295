import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/shadcn/Accordion";

const referralRules = [
  {
    question: "Who is eligible to participate in the referral program?",
    answer:
      "All registered users of Sponsearly are eligible to participate in the referral program. You must have a valid account to invite others and earn points.",
  },
  {
    question: "How do I earn points through referrals?",
    answer:
      "You earn points when a referred friend signs up using your unique invitation link and authenticates their Twitch profile. Each successful referral increases your total points.",
  },
  {
    question: "What can I win with my referral points?",
    answer:
      "Your accumulated points increase your chances of winning exciting monthly prizes. The more points you have, the higher your chances of being selected as a winner in our monthly draws.",
  },
  {
    question: "Are there any geographical restrictions for prize shipping?",
    answer:
      "Yes, currently we can only ship prizes within Europe and America. If you reside outside Europe and America and win a prize, we will provide a digital reward or equivalent compensation where possible.",
  },
  {
    question: "What happens if fraudulent activity is detected?",
    answer:
      "We have a zero-tolerance policy for fraud. Any attempts to manipulate the referral system, including creating fake accounts or spamming, will result in immediate disqualification and potential suspension of your Sponsearly account.",
  },
  {
    question: "Is there a limit to how many friends I can refer?",
    answer:
      "No, there is no limit! You can refer as many streamer friends as you like. Each successful referral increases your points and chances to win monthly prizes.",
  },
  {
    question: "When will there be prizes?",
    answer: "The first round of prizes will be on 30th of October!",
  },
];

const ReferralRulesAccordion = () => {
  return (
    <section className="p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-center text-3xl font-bold mb-6">
          Referral Program Rules
        </h2>
        <Accordion type="single" collapsible>
          {referralRules.map((rule, index) => (
            <AccordionItem key={index} value={`rule-${index}`}>
              <AccordionTrigger>{rule.question}</AccordionTrigger>
              <AccordionContent>{rule.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default ReferralRulesAccordion;
