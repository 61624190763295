// src/components/Table/CreatorTable.tsx

import React from "react";
import { IUser } from "../../../../interfaces/IUser";
import { truncateItems } from "../utils/utils";
import ProfilePicture from "../../../../components/ProfilePicture/ProfilePicture";
import { RangeFilter, Filters } from "../CreatorSearch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/components/shadcn/Table";
import MultiSelect, {
  IMultiSelectElement,
} from "src/components/MultiSelect/MultiSelect";
import { groupedProductOptions } from "src/utils/selectOptions/productOptions";
import { audienceOptions } from "src/utils/selectOptions/regionOptions";
import { categoryOptions } from "src/utils/selectOptions/categoryOptions";

type ColumnType = {
  Header: string;
  accessor: keyof IUser;
  filterKey?: keyof Filters;
  width?: string; // Optional width property
  sortable?: boolean;
};

interface CreatorTableProps {
  creators: IUser[];
  // onSort: (field: "followers_total" | "followers_change") => void;
  onFilterChange: (
    field: keyof Filters,
    value: string | IMultiSelectElement[] | RangeFilter,
    rangePart?: "min" | "max"
  ) => void;
  filters: Filters;
  // sort: SortType;
  isCreatorSearch: boolean;
}

const CreatorTable: React.FC<CreatorTableProps> = ({
  creators,
  // onSort,
  onFilterChange,
  filters,
  // sort,
  isCreatorSearch,
}) => {
  let columns: ColumnType[] = [
    {
      Header: "Username",
      accessor: "displayName",
      filterKey: "displayName",
      width: "15%",
      sortable: false, // Disable sorting
    },
    {
      Header: "Audience",
      accessor: "audience",
      filterKey: "audience",
      width: "15%",
      sortable: false, // Disable sorting
    },
    {
      Header: "Categories",
      accessor: "favoriteCategories",
      filterKey: "favoriteCategories",
      width: "30%",
      sortable: false, // Disable sorting
    },
    {
      Header: "Products",
      accessor: "favoriteProducts",
      filterKey: "favoriteProducts",
      width: "30%",
      sortable: false, // Disable sorting
    },
  ];

  if (isCreatorSearch) {
    columns = columns.concat([
      {
        Header: "Followers",
        accessor: "followers_total",
        filterKey: "followers_total",
        width: "8%",
        sortable: false,
      },
      {
        Header: "Followers gained in week",
        accessor: "followers_change",
        filterKey: "followers_change",
        width: "10%",
        sortable: false,
      },
    ]);
  } else {
    columns = columns.map((col) => {
      if (col.Header === "Username") {
        col.Header = "Brand";
        col.width = "20%";
      }
      if (col.Header === "Categories") {
        col.Header = "Open Categories";
        col.width = "20%";
      }
      if (col.Header === "Products") {
        col.Header = "Open Products";
        col.width = "20%";
      }
      return col;
    });
  }

  const handleRowClick = (creatorId?: string) => {
    window.open(`/creator-profile/${creatorId}`, "_blank");
  };

  return (
    <div className="overflow-x-auto mt-6">
      <Table>
        <TableHeader>
          {/* Header Row for Column Names */}
          <TableRow>
            {columns.map((column) => (
              <TableHead
                key={column.accessor}
                className="cursor-pointer"
                style={{ width: column.width }}
              >
                {column.Header}{" "}
              </TableHead>
            ))}
          </TableRow>
          {/* Filter Row */}
          <TableRow>
            {columns.map((column) => (
              <TableHead key={column.accessor}>
                {/* Handle filters based on filterKey */}
                {column.filterKey === "followers_change" ||
                column.filterKey === "followers_total" ? (
                  <div className="flex flex-row gap-1">
                    <input
                      type="number"
                      value={
                        (filters[column.filterKey] as RangeFilter)?.min || ""
                      }
                      onChange={(e) =>
                        onFilterChange(column.filterKey!, e.target.value, "min")
                      }
                      className="w-1/2 px-2 py-1 border rounded text-secondaryText bg-white"
                      placeholder="Min"
                    />
                    <input
                      type="number"
                      value={
                        (filters[column.filterKey] as RangeFilter)?.max || ""
                      }
                      onChange={(e) =>
                        onFilterChange(column.filterKey!, e.target.value, "max")
                      }
                      className="w-1/2 px-2 py-1 border rounded text-secondaryText bg-white"
                      placeholder="Max"
                    />
                  </div>
                ) : column.filterKey === "audience" ? (
                  <MultiSelect
                    elements={audienceOptions}
                    selectedElements={filters.audience}
                    setSelectedElements={(elements) =>
                      onFilterChange("audience", elements)
                    }
                  />
                ) : column.filterKey === "favoriteCategories" ? (
                  <MultiSelect
                    elements={categoryOptions}
                    selectedElements={filters.favoriteCategories}
                    setSelectedElements={(elements) =>
                      onFilterChange("favoriteCategories", elements)
                    }
                  />
                ) : column.filterKey === "favoriteProducts" ? (
                  <MultiSelect
                    elements={groupedProductOptions}
                    selectedElements={filters.favoriteProducts}
                    setSelectedElements={(elements) =>
                      onFilterChange("favoriteProducts", elements)
                    }
                  />
                ) : column.filterKey === "displayName" ? (
                  <input
                    type="text"
                    value={filters.displayName || ""}
                    onChange={(e) =>
                      onFilterChange("displayName", e.target.value)
                    }
                    className="w-full px-2 py-1 border rounded text-secondaryText bg-white"
                    placeholder={`Filter by ${column.Header}`}
                  />
                ) : null}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {creators.map((creator) => (
            <TableRow
              key={creator.id}
              onClick={() => handleRowClick(creator?.id)}
              className="cursor-pointer hover:bg-backgroundSecondary transition-colors duration-150"
              tabIndex={0} // Make rows focusable for accessibility
              onKeyPress={(e) => {
                if (e.key === "Enter") handleRowClick(creator?.id);
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.accessor}
                  style={{ width: column.width }}
                  className={`truncate overflow-hidden text-ellipsis ${
                    column.accessor === "displayName" && "py-0"
                  }`}
                >
                  {column.accessor === "displayName" ? (
                    <div className="flex flex-row items-center justify-start py-0">
                      <ProfilePicture userId={creator?.id ?? ""} size="2" />{" "}
                      {/* Adjust size as needed */}
                      <div className="ml-4 flex-grow justify-center items-center align-middle">
                        {creator[column.accessor]}
                      </div>
                    </div>
                  ) : column.accessor === "followers_change" &&
                    creator?.followers_change !== undefined &&
                    creator.followers_gained !== undefined ? (
                    <>
                      <div className="flex justify-start">
                        <span
                          className={`flex-1 mr-2 ${
                            creator.followers_gained >= 0
                              ? "text-primary"
                              : "text-accent"
                          }`}
                        >
                          {creator.followers_gained}
                        </span>
                        <span
                          className={`flex-1 ${
                            creator.followers_change >= 0
                              ? "text-primary"
                              : "text-accent"
                          }`}
                        >
                          {creator.followers_change.toFixed(2)}%
                        </span>
                      </div>
                    </>
                  ) : Array.isArray(creator[column.accessor]) ? (
                    truncateItems(
                      creator[column.accessor],
                      filters[
                        column.filterKey as keyof Filters
                      ] as IMultiSelectElement[]
                    )
                  ) : (
                    creator[column.accessor]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CreatorTable;
