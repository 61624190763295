import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { useAuth } from "../../context/AuthContext";
import { app } from "../../firebaseConfig";
import { getCheckoutUrl } from "./stripePayment";

const Payment = () => {
  const { currentUser } = useAuth(); // Get the current user

  const handlePurchase = async () => {
    if (!currentUser) {
      console.error("User must be authenticated");
      return;
    }
    try {
      const priceId = "price_1ONXm7FnMM3vaqrt1zDBffhP";
      let checkoutUrl = "";
      checkoutUrl = await getCheckoutUrl(app, priceId);
      window.location.href = checkoutUrl; // Redirect to Stripe checkout
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <SecondaryButton onClick={handlePurchase}>
      Subscribe for only 99,00 EUR/month
    </SecondaryButton>
  );
};

export default Payment;
