import { IMultiSelectElement } from "../../components/MultiSelect/MultiSelect";

export const audienceOptions: IMultiSelectElement[] = [
  { label: "Afrikaans", value: "afrikaans" },
  { label: "Albanian", value: "albanian" },
  { label: "Amharic", value: "amharic" },
  { label: "Arabic", value: "arabic" },
  { label: "Armenian", value: "armenian" },
  { label: "Azerbaijani", value: "azerbaijani" },
  { label: "Bambara", value: "bambara" },
  { label: "Bashkir", value: "bashkir" },
  { label: "Basque", value: "basque" },
  { label: "Bengali", value: "bengali" },
  { label: "Bhojpuri", value: "bhojpuri" },
  { label: "Bosnian", value: "bosnian" },
  { label: "Bulgarian", value: "bulgarian" },
  { label: "Burmese", value: "burmese" },
  { label: "Cebuano", value: "cebuano" },
  { label: "Chechen", value: "chechen" },
  { label: "Chinese", value: "chinese" },
  { label: "Chittagonian", value: "chittagonian" },
  { label: "Croatian", value: "croatian" },
  { label: "Czech", value: "czech" },
  { label: "Danish", value: "danish" },
  { label: "Dari", value: "dari" },
  { label: "Dutch", value: "dutch" },
  { label: "English", value: "english" },
  { label: "Farsi", value: "farsi" },
  { label: "Filipino", value: "filipino" },
  { label: "Finnish", value: "finnish" },
  { label: "French", value: "french" },
  { label: "Fula", value: "fula" },
  { label: "Georgian", value: "georgian" },
  { label: "German", value: "german" },
  { label: "Greek", value: "greek" },
  { label: "Gujarati", value: "gujarati" },
  { label: "Haitian Creole", value: "haitian_creole" },
  { label: "Hausa", value: "hausa" },
  { label: "Hebrew", value: "hebrew" },
  { label: "Hindi", value: "hindi" },
  { label: "Hungarian", value: "hungarian" },
  { label: "Igbo", value: "igbo" },
  { label: "Ilocano", value: "ilocano" },
  { label: "Indonesian", value: "indonesian" },
  { label: "Italian", value: "italian" },
  { label: "Japanese", value: "japanese" },
  { label: "Javanese", value: "javanese" },
  { label: "Kannada", value: "kannada" },
  { label: "Kazakh", value: "kazakh" },
  { label: "Khmer", value: "khmer" },
  { label: "Kinyarwanda", value: "kinyarwanda" },
  { label: "Konkani", value: "konkani" },
  { label: "Korean", value: "korean" },
  { label: "Kurdish", value: "kurdish" },
  { label: "Kyrgyz", value: "kyrgyz" },
  { label: "Lao", value: "lao" },
  { label: "Latvian", value: "latvian" },
  { label: "Lithuanian", value: "lithuanian" },
  { label: "Luganda", value: "luganda" },
  { label: "Madurese", value: "madurese" },
  { label: "Maithili", value: "maithili" },
  { label: "Malay", value: "malay" },
  { label: "Malayalam", value: "malayalam" },
  { label: "Mandinka", value: "mandinka" },
  { label: "Marathi", value: "marathi" },
  { label: "Mongolian", value: "mongolian" },
  { label: "Nepali", value: "nepali" },
  { label: "Norwegian", value: "norwegian" },
  { label: "Odia", value: "odia" },
  { label: "Pashto", value: "pashto" },
  { label: "Polish", value: "polish" },
  { label: "Portuguese", value: "portuguese" },
  { label: "Punjabi", value: "punjabi" },
  { label: "Quechua", value: "quechua" },
  { label: "Romanian", value: "romanian" },
  { label: "Russian", value: "russian" },
  { label: "Serbian", value: "serbian" },
  { label: "Shona", value: "shona" },
  { label: "Sindhi", value: "sindhi" },
  { label: "Sinhala", value: "sinhala" },
  { label: "Slovak", value: "slovak" },
  { label: "Slovenian", value: "slovenian" },
  { label: "Somali", value: "somali" },
  { label: "Spanish", value: "spanish" },
  { label: "Sundanese", value: "sundanese" },
  { label: "Swahili", value: "swahili" },
  { label: "Swedish", value: "swedish" },
  { label: "Tagalog", value: "tagalog" },
  { label: "Tajik", value: "tajik" },
  { label: "Tamil", value: "tamil" },
  { label: "Telugu", value: "telugu" },
  { label: "Thai", value: "thai" },
  { label: "Tigrinya", value: "tigrinya" },
  { label: "Turkish", value: "turkish" },
  { label: "Turkmen", value: "turkmen" },
  { label: "Ukrainian", value: "ukrainian" },
  { label: "Urdu", value: "urdu" },
  { label: "Uyghur", value: "uyghur" },
  { label: "Uzbek", value: "uzbek" },
  { label: "Vietnamese", value: "vietnamese" },
  { label: "Wolof", value: "wolof" },
  { label: "Xhosa", value: "xhosa" },
  { label: "Yoruba", value: "yoruba" },
  { label: "Zulu", value: "zulu" },
];
