import { useLocation } from "react-router-dom";
import Footer from "./Footer";

const FooterHandler = () => {
  const location = useLocation();
  const isChatRoute = location.pathname.includes("chat/");

  return <>{!isChatRoute && <Footer />}</>;
};

export default FooterHandler;
