import "./FAQAccordion.css";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/components/shadcn/Accordion";

const myfaqs = [
  {
    question: "How does Sponsearly match content creators with brands?",
    answer:
      "Sponsearly uses an advanced algorithm that considers the creator's content style, audience demographics, and engagement rates to match them with brands that share similar values and target audiences.",
    isOpen: false,
  },
  {
    question:
      "What kind of analytics does Sponsearly provide to content creators?",
    answer:
      "Sponsearly provides comprehensive analytics, including audience engagement, sponsorship performance metrics, and insights into how different types of content perform with various audience segments.",
    isOpen: false,
  },
  {
    question:
      "As a brand, how can I ensure I'm partnering with the right creators?",
    answer:
      "Brands can use our detailed creator profiles, previous campaign performance, and audience demographics to make informed decisions about which creators align best with their marketing objectives.",
    isOpen: false,
  },
  {
    question: "Is there a trial period or a demo available for new users?",
    answer:
      "Yes, new users can access a demo version of our platform, which includes limited functionality to explore our features and interface before committing to a full subscription.",
    isOpen: false,
  },
  {
    question:
      "What support does Sponsearly offer for dispute resolution between creators and brands?",
    answer:
      "We have a dedicated support team to assist with any disputes or issues that arise, ensuring fair and prompt resolution in line with our platform's policies and user agreements.",
    isOpen: false,
  },
];

const FAQ = () => {
  return (
    <section className="p-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-center text-3xl font-bold mb-6">
          Frequently Asked Questions
        </h2>
        <Accordion type="single" collapsible>
          {myfaqs.map((faq, index) => (
            <AccordionItem key={index} value={faq.question + index}>
              <AccordionTrigger>{faq.question}</AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FAQ;
