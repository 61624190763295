import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getCheckoutUrl, getPortalUrl } from "../../Payment/stripePayment";
import { app, auth, db } from "../../../firebaseConfig";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import DangerButton from "../../../components/Buttons/DangerButton";

const Account = () => {
  const { currentUser, isPremium } = useAuth();

  const userName = currentUser?.displayName;
  const email = currentUser?.email;

  const [checkoutUrl, setCheckoutUrl] = useState("");
  const [portalUrl, setPortalUrl] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const createUrls = async () => {
      if (!currentUser) {
        console.error("User must be authenticated");
        return;
      }
      setIsLoading(true);

      try {
        const priceId = "price_1ONXm7FnMM3vaqrt1zDBffhP";
        let tempCheckoutUrl = "";
        console.log("Before checkouotUrl");

        tempCheckoutUrl = await getCheckoutUrl(app, priceId);

        console.log("Before PortalUrl");

        const tempPortalUrl = await getPortalUrl(app);
        setPortalUrl(tempPortalUrl);
        setCheckoutUrl(tempCheckoutUrl);
        console.log("Reached end");
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    createUrls();
  }, [currentUser]);

  const handlePurchase = async () => {
    window.location.href = checkoutUrl; // Redirect to Stripe checkout
  };

  const manageSubscription = async () => {
    window.location.href = portalUrl; // Redirect to Stripe checkout
  };

  const deleteAccount = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );

    if (confirm && auth.currentUser) {
      try {
        // Delete user data from Firestore
        const userRef = doc(db, "users", auth.currentUser.uid);
        await deleteDoc(userRef);

        // Delete user account
        await auth.currentUser.delete();
        alert("Account and data deleted successfully");
        navigate("/"); // Redirect to login or home page
      } catch (error: any) {
        console.error("Error deleting account and data:", error.message);
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      {isLoading && <LoadingSpinner />}
      <div>
        <div className="text-xl mb-1">Signed in as {userName}</div>
        <div className="text-xl">Email: {email}</div>
      </div>
      <div className="mt-4">
        {isPremium ? (
          <PrimaryButton onClick={() => manageSubscription()}>
            Manage Subscription
          </PrimaryButton>
        ) : (
          <div className="flex">
            <div className="flex flex-col items-center">
              You have a brand account but no active subscription.
              <p className="mb-4">Please subscribe to use Sponsearly!</p>
              <div className="flex justify-center space-x-4">
                <div className="max-w-sm rounded overflow-hidden shadow-lg bg-backgroundSecondary p-6">
                  <div className="font-bold text-xl mb-2 flex justify-center">
                    Subscription Plan
                  </div>
                  <ul className="list-disc pl-5 mb-4">
                    <li>Searching through thousands of content creators</li>
                    <li>Chatting with individuals</li>
                    <li>Possibility to cancel on a monthly basis</li>
                    {/* Add more features here */}
                  </ul>
                  <div className="flex justify-center">
                    <PrimaryButton onClick={() => handlePurchase()}>
                      Subscribe for 99 EUR/month
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end mt-4">
        <DangerButton onClick={() => deleteAccount()}>
          Delete Account
        </DangerButton>
      </div>
    </div>
  );
};

export default Account;
