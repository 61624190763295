import { useNavigate } from "react-router-dom";
import ContentPage from "../../components/ContentPage/ContentPage";
import { useAuth } from "../../context/AuthContext";
import Payment from "../Payment/Payment";
import { Fragment } from "react";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const Pricing = () => {
  const { isPremium, currentUser } = useAuth();

  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signup");
  };
  const content = {
    headline: "Affordable Pricing for Brands",
    imageUrl: "/assets/images/background-pricing.png", // Adjust the path as necessary
    contentSections: [
      {
        headline: "Unlimited Access at Just $99/Month",
        text: "Gain full access to our extensive database of creators and their preferences for only $99 per month per account. Whether you're searching for micro-influencers or established content creators, our platform provides you with the tools to find the perfect match quickly and efficiently.",
      },
      {
        headline: "Flexible, Month-to-Month Subscriptions",
        text: "We understand the fast-paced nature of influencer marketing. That’s why Sponsearly offers month-to-month subscriptions — no long-term commitments. If you ever feel that our service isn’t contributing to your success, you can cancel anytime, hassle-free. We’re dedicated to continuously improving our platform and value your feedback to better serve your needs.",
      },
      {
        headline: "Exciting Updates on the Horizon",
        text: "We’re not stopping here—our team is hard at work expanding our offerings to include more content platforms and categories. Stay tuned for exciting new features and opportunities that will help you connect with creators across diverse niches.",
      },
    ],
  };

  return (
    <Fragment>
      <ContentPage {...content}>
        <div className="flex justify-center align-middle m-10">
          {!isPremium && currentUser && <Payment />}
        </div>
      </ContentPage>
      <div className="flex justify-center align-middle mb-10">
        <PrimaryButton onClick={handleGetStarted}>Get Started</PrimaryButton>
      </div>
    </Fragment>
  );
};

export default Pricing;
