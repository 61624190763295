export function formatDateString(dateString: string): string {
  const date = new Date(dateString);

  // Formatting options
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return date.toLocaleString("en-US", options);
}

export function formatDateTimeString(dateString: string): string {
  const date = new Date(dateString);

  // Formatting options
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleString("de-de", options);
}
