// src/components/ProfilePicture.js
import { useState, useEffect, FC } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebaseConfig"; // Adjust the path as necessary
import defaultProfilePic from "../../assets/default-profile.png"; // Path to a default profile image

interface ProfilePictureProps {
  userId: string;
  size?: string;
}

const ProfilePicture: FC<ProfilePictureProps> = ({ userId, size = "12" }) => {
  // Assuming size is now given in rem units
  const [profilePicUrl, setProfilePicUrl] = useState("");

  useEffect(() => {
    // if (!userId) {
    //   setProfilePicUrl(defaultProfilePic);
    //   return;
    // }
    if (userId) {
      const profilePicRef = ref(storage, `profilePictures/${userId}`);
      getDownloadURL(profilePicRef)
        .then((url) => {
          setProfilePicUrl(url);
        })
        .catch(() => {
          setProfilePicUrl(defaultProfilePic);
        });
    }
  }, [userId]);

  // Convert size from rem to pixels for inline styling
  // Assuming 1rem = 16px, adjust this factor based on your site's base font size if different
  const sizeInPixels = parseInt(size) * 16;

  if (profilePicUrl === "") {
    return null;
  } else {
    return (
      <img
        src={profilePicUrl}
        alt="Profile"
        style={{ height: `${sizeInPixels}px`, width: `${sizeInPixels}px` }}
        className="rounded-full mx-auto object-cover"
      />
    );
  }
};

export default ProfilePicture;
