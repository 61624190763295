// SomeContentSite.js
import ContentPage from "../../../components/ContentPage/ContentPage";

const SuccessStories = () => {
  const content = {
    headline: "My Journey to Success with Sponsearly",
    imageUrl: "/assets/images/successstories.png", // Adjust the path as necessary
    contentSections: [
      {
        headline: "A Dream Turned Reality",
        text: "As a content creator, my journey has always been fueled by passion and a relentless drive to share my unique perspective with the world. I remember the early days of my content creation – full of enthusiasm but equally lined with the uncertainty of turning my passion into a sustainable career. That's when I discovered Sponsearly, a platform that promised to bridge the gap between creators like me and potential sponsors.",
      },
      {
        headline: "The Perfect Match",
        text: "The first thing that struck me about Sponsearly was its intuitive matchmaking system. It felt like they truly understood the essence of my content and the interests of my audience. Within weeks, I was connecting with brands that not only aligned with my niche but also believed in my vision. It was a game-changer – moving from merely creating content to creating content with a purpose and support.",
      },
      {
        headline: "Growth Beyond Numbers",
        text: "It wasn't just about financial backing. The sponsorships brought validation, a sense of achievement, and a growing audience. Each collaboration opened doors to new opportunities, and I found my content reaching wider and more diverse audiences. The real-time analytics provided by Sponsearly helped me fine-tune my approach, ensuring that each piece of content was impactful and resonated with my viewers.",
      },
      {
        headline: "A Community of Support",
        text: "What I cherish most about Sponsearly is the sense of community it fosters. I've connected with other creators, sharing experiences and growth strategies. We're a network, a family, growing together. The platform's continuous support and resources have been invaluable, from resolving any disputes to offering insights into maximizing brand partnerships.",
      },
      {
        headline: "Looking Ahead",
        text: "Today, I stand proud, not just as a content creator, but as a successful digital entrepreneur. My relationship with Sponsearly has been a cornerstone of this success. It's more than just a platform; it's a catalyst for realizing dreams. For anyone venturing into the world of content creation, looking to make their mark and seeking the right support, I can't recommend Sponsearly enough. It's not just about landing sponsorships; it's about embarking on a journey of growth and endless possibilities.",
      }, // Add more paragraphs as needed
    ],
  };

  return <ContentPage {...content} />;
};

export default SuccessStories;
