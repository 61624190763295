import { createContext, useState, FC, useContext, ReactNode } from "react";
import Toast from "../components/Toast/Toast";

type ToastFunctionType = (text: string, isSucess: boolean) => void; // Defining the type for Toast function.

const ToastContext = createContext<ToastFunctionType>(() => {}); // Setting an empty function as default value.

interface ToastProviderProps {
  children: ReactNode; // Define the type for children here
}

export const enum TOASTMESSAGES {
  SUCCESS = "Successfully updated!",
  DOWNLOAD = "Download successful!",
  CONFIRM_EMAIL = "Please confirm your email!",
  FAIL = "Something went wrong with the update!",
  CONFIRM_EMAIL_SEND = "Email sent!",
}

export const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState({
    text: "",
    show: false,
    isSuccess: false,
  });

  const showToast: ToastFunctionType = (text, isSuccess) => {
    // Explicitly stating that showToast is of type ToastFunctionType
    setToast({ text, show: true, isSuccess });
    setTimeout(() => setToast({ text: "", show: false, isSuccess }), 1900);
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toast.show && (
        <Toast key={Date.now()} isSuccess={toast.isSuccess} text={toast.text} />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
