import { FC } from "react";
import Select, { MultiValue } from "react-select";
import { GroupedOption } from "../../utils/selectOptions/productOptions";

export interface IMultiSelectElement {
  value: string;
  label: string;
}

interface MultiSelectProps {
  elements: GroupedOption[] | IMultiSelectElement[];
  selectedElements: IMultiSelectElement[];
  setSelectedElements: (elements: IMultiSelectElement[]) => void; // Add this function prop
}

// Custom styles for react-select
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    // background: "#fff",
    // borderColor: state.isFocused ? "#666" : "#ddd",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#aaa" : "#ddd",
    },
    padding: "0.2rem",
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: "black",
    // backgroundColor: state.isFocused ? "#f3f3f3" : "#fff",
    // "&:active": {
    //   backgroundColor: "red",
    // },
  }),
  // Add other custom styles as needed
};

const MultiSelect: FC<MultiSelectProps> = ({
  elements,
  selectedElements,
  setSelectedElements, // Use the correct prop name
}) => {
  const handleChange = (
    newValue: MultiValue<IMultiSelectElement>
    // actionMeta: ActionMeta<IMultiSelectElement>
  ) => {
    setSelectedElements(newValue as IMultiSelectElement[]);
  };

  return (
    <Select
      className=""
      options={elements}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={handleChange}
      styles={customStyles}
      //   formatGroupLabel={formatGroupLabel}
      value={selectedElements} // The value should be the array of selected elements
    />
  );
};

export default MultiSelect;
