import { Fragment, useEffect, useState } from "react";
import FAQ from "./components/FAQ/FAQ";
// import FeaturesSection from "./components/FeaturesSection/FeaturesSection";
import HeroSection from "./components/HeroSection/HeroSection";
// import Testimonials from "./components/Testimonials/Tesitmonials";
import Features from "./components/Features/Features";
import ContactForm from "./components/ContactForm/ContactForm";
import WelcomeModal from "../../components/WelcomeModal/WelcomeModal";
import { doc, increment, setDoc } from "firebase/firestore";
import { db } from "src/firebaseConfig";

const LandingPageReddit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const hasVisitedReddit = localStorage.getItem("hasVisitedReddit");

    if (!hasVisitedReddit) {
      setIsModalOpen(true);
      localStorage.setItem("hasVisitedReddit", "true");

      // Increment the count in Firestore
      const incrementRedditCount = async () => {
        try {
          const redditDocRef = doc(db, "referrerStatistics", "reddit");
          await setDoc(
            redditDocRef,
            {
              count: increment(1),
            },
            { merge: true }
          );
        } catch (error) {
          console.error("Error incrementing Reddit count:", error);
        }
      };

      incrementRedditCount();
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Fragment>
      <WelcomeModal isOpen={isModalOpen} onClose={closeModal} />

      <HeroSection />
      <div className="flex-grow ">
        {/* <FeaturesSection /> */}
        <Features />
        {/* <Testimonials /> */}
        <FAQ />
        <ContactForm />
      </div>
    </Fragment>
  );
};

export default LandingPageReddit;
