import { FC } from "react";
import { ILastDays } from "../../../interfaces/ITwitch";

const TwitchMetrics: FC<{
  twitchMetrics: ILastDays;
  twitchName?: string;
}> = ({ twitchMetrics, twitchName }) => {
  // Helper function to format numbers with thousand separators
  const formatNumber = (number: number): string =>
    new Intl.NumberFormat("de-DE").format(number);

  // Helper function to calculate percentage change with fallback
  const calculatePercentageChange = (
    current: number,
    previous: number
  ): string => {
    if (previous === 0 || isNaN(current) || isNaN(previous)) {
      return ""; // Avoid NaN or Infinity
    }
    const change = ((current - previous) / previous) * 100;
    return isFinite(change) ? `${change.toFixed(2)}%` : "";
  };

  return (
    <div className="bg-backgroundSecondary  p-4 my-4 rounded-lg">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div className="text-center">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.duration.lastPeriod,
                  twitchMetrics.duration.previousPeriod
                )
              ) > 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.duration.lastPeriod,
              twitchMetrics.duration.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(Math.round(twitchMetrics.duration.lastPeriod / 60))}
          </p>
          <p className="text-sm uppercase tracking-widest">Hours Streamed</p>
        </div>
        <div className="text-center">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.weightedAvgViewers.lastPeriod,
                  twitchMetrics.weightedAvgViewers.previousPeriod
                )
              ) >= 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.weightedAvgViewers.lastPeriod,
              twitchMetrics.weightedAvgViewers.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(
              Math.round(twitchMetrics.weightedAvgViewers.lastPeriod)
            )}
          </p>
          <p className="text-sm uppercase tracking-widest">Average Viewers</p>
        </div>
        <div className="text-center">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.peakViewers.lastPeriod,
                  twitchMetrics.peakViewers.previousPeriod
                )
              ) >= 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.peakViewers.lastPeriod,
              twitchMetrics.peakViewers.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(twitchMetrics.peakViewers.lastPeriod)}
          </p>
          <p className="text-sm uppercase tracking-widest">Peak Viewers</p>
        </div>
        <div className="text-center">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.watchedMinutes.lastPeriod,
                  twitchMetrics.watchedMinutes.previousPeriod
                )
              ) >= 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.watchedMinutes.lastPeriod,
              twitchMetrics.watchedMinutes.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(
              Math.round(twitchMetrics.watchedMinutes.lastPeriod / 60)
            )}
          </p>
          <p className="text-sm uppercase tracking-widest">Hours Watched</p>
        </div>
        <div className="text-center col-span-2 md:col-span-1">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.followersGained.lastPeriod,
                  twitchMetrics.followersGained.previousPeriod
                )
              ) >= 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.followersGained.lastPeriod,
              twitchMetrics.followersGained.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(twitchMetrics.followersGained.lastPeriod)}
          </p>
          <p className="text-sm uppercase tracking-widest">Followers Gained</p>
        </div>
        <div className="text-center col-span-2 md:col-span-1">
          <p
            className={`text-l font-bold ${
              parseFloat(
                calculatePercentageChange(
                  twitchMetrics.endFollowers.lastPeriod,
                  twitchMetrics.endFollowers.previousPeriod
                )
              ) >= 0
                ? "text-primary"
                : "text-accent"
            }`}
          >
            {calculatePercentageChange(
              twitchMetrics.endFollowers.lastPeriod,
              twitchMetrics.endFollowers.previousPeriod
            )}
          </p>
          <p className="text-3xl font-bold">
            {formatNumber(twitchMetrics.endFollowers.lastPeriod)}
          </p>
          <p className="text-sm uppercase tracking-widest">Total Followers</p>
        </div>
      </div>
    </div>
  );
};

export default TwitchMetrics;
