import { useState } from "react";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import { callContactMessages } from "../../../../API/callContactMessage";
import { TOASTMESSAGES, useToast } from "../../../../context/ToastContext";
import { Label } from "src/components/shadcn/Label";
import { Input } from "src/components/shadcn/Input";
import { Textarea } from "src/components/shadcn/Textarea";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const showToast = useToast();

  const handleSend = async () => {
    if (name !== "" && email !== "" && message !== "") {
      try {
        await callContactMessages(email, name, message);
        setName("");
        setEmail("");
        setMessage("");
        showToast(TOASTMESSAGES.CONFIRM_EMAIL_SEND, true);
      } catch {
        console.log("Error sending Contact Form");
      }
    }
  };

  return (
    <div className="flex justify-center items-center bg-white mx-auto">
      <div
        className="wow fadeInUp shadow-three mb-12 rounded-sm w-full max-w-6xl mx-auto py-11 lg:mb-5   "
        data-wow-delay=".15s"
      >
        <h2 className="mb-3 text-2xl px-4 font-bold text-black sm:text-3xl lg:text-2xl xl:text-3xl">
          Get Assistance by Opening a Ticket
        </h2>
        <p className="mb-12 text-base px-4 font-medium">
          We will respond to your inquiry as soon as possible via email.
        </p>
        <div className="flex flex-wrap">
          <div className="w-full px-4 md:w-1/2 flex-1">
            <div className="mb-4">
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="name">Your Name</Label>
                <Input
                  type="text"
                  id="name"
                  placeholder="Your Name"
                  className="w-full"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 flex-1">
            <div className="mb-4">
              <div className="grid w-full items-center gap-1.5">
                <Label htmlFor="email">Your Email</Label>
                <Input
                  type="email"
                  id="email"
                  placeholder="Email"
                  className="w-full"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="w-full px-4">
            <div className="mb-4">
              <Label htmlFor="message">Your Message</Label>
              <Textarea
                name="message"
                rows={5}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your Message"
              ></Textarea>
            </div>
          </div>
          <div className="w-full px-4">
            <PrimaryButton onClick={() => handleSend()}>Submit</PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
