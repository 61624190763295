// SomeContentSite.js
import ContentPage from "../../components/ContentPage/ContentPage";

const HowItWorks = () => {
  const content = {
    headline: "How It Works",
    imageUrl: "/assets/images/background-how-it-works.png", // Adjust the path as necessary
    contentSections: [
      {
        headline:
          "Discover your next big sponsorship opportunity: connect early on with Brands and Creators on Sponsearly!",
        text: "Sponsearly is connecting Brands with Content Creators at an early stage of their career. You can skip the time-consuming search for new potential partnerships. Our platform connects Brands and Content Creators based on your actual interests and openness for cooperation.",
        imageUrl: "/assets/images/how-it-works-1.png",
      },
      {
        headline:
          "Skip the search - dive into success: early career sponsorship opportunities with Sponsearly!",
        text: "Sponsearly enables brand managers to identify rising stars within the content creator landscape - long before they are charging for each and every cooperation. You can track the performance of each of our talents and identify those that really shoot for the stars. Also, Content Creators show you, for which of your products they are interested to cooperate. No more cold calls and ghosted messages!",
        imageUrl: "/assets/images/how-it-works-2.png",
      },
      {
        headline:
          "Get ahead of the curve - find your ideal brand partnership early on Sponsearly!",
        text: "For you as Content Creator, Sponsearly is the first step forward to gain attention of brands and be found for product sponsorship. We help you get their attention and land your first deal with brands - based on your interests. Get exactly what you are looking for! ",
        imageUrl: "/assets/images/how-it-works-3.png",
      },
    ],
  };

  return <ContentPage {...content} />;
};

export default HowItWorks;
