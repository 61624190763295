import { FC, useState } from "react";
import ChatRoom from "../Chatroom/ChatRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface ChatPopupProps {
  chatId: string;
  setIsChatPopupOpen: (isOpen: boolean) => void;
  isChatBoxOpen: boolean;
}

const ChatPopup: FC<ChatPopupProps> = ({
  chatId,
  setIsChatPopupOpen,
  isChatBoxOpen,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      style={isChatBoxOpen ? { right: "25rem" } : { right: "9.25rem" }}
      className={`fixed bottom-2 z-50 w-96 ${
        isOpen ? "h-96" : "h-12"
      }  bg-backgroundPrimary shadow-lg rounded-lg overflow-hidden`}
    >
      <ChatRoom chatPopUpId={chatId} />
      <button
        className="absolute top-0 right-6 p-2 pt-3 text-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <FontAwesomeIcon icon={faMinus} className="mr-2" />
        ) : (
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
        )}
      </button>
      <button
        className="absolute top-0 right-0 p-2 pt-3 text-white"
        onClick={() => setIsChatPopupOpen(false)}
      >
        <FontAwesomeIcon icon={faX} className="mr-2" />
      </button>
    </div>
  );
};

export default ChatPopup;
