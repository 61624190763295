import { FC, ReactNode } from "react";
import { Button } from "../shadcn/Button";

export interface DangerButtonProps {
  onClick: () => void;
  children: ReactNode; // This allows you to pass any React node as children
}

const DangerButton: FC<DangerButtonProps> = ({ onClick, children }) => {
  return (
    <Button
      onClick={onClick}
      // className="bg-accent hover:bg-secondaryHover text-white font-bold py-2 px-4 rounded"
      variant={"destructive"}
    >
      {children}
    </Button>
  );
};

export default DangerButton;
