// SomeContentSite.js
import ContentPage from "../../../components/ContentPage/ContentPage";

const HowItWorksCreator = () => {
  const content = {
    headline: "How it works!",
    imageUrl: "/assets/images/background-how-it-works.png", // Adjust the path as necessary
    contentSections: [
      {
        headline: "",
        text: "Welcome to Sponsearly! We want to help you achieve your goals and become a successful content creator by getting into early Sponsoring Partnerships with the brands you like. You can score your first product sponsoring here and get the attention you deserve - without any commitment or cost for you.",
        imageUrl: "/assets/images/how-it-works-1.png",
      },
      {
        headline: "",
        text: "To get started you need to fill out your profile, select the products and product categories which you want to promote on your channel and connect your Twitch Channel, so we can show your activity to the Brand! We do not require any Credit Card information - because our service is free for you :) Just sing up with your Twitch Account for verification.",
        imageUrl: "/assets/images/how-it-works-2.png",
      },
      {
        headline: "",
        text: "When you have selected the products you want to feature in your streams and verified your Twitch profile, we will show your activity to the Brands. While getting started with your stream, having a growing follower base and a steady on-air time gets you the necessary attention from the Brands to be selected for actual product sponsorship. Brands will reach out to you and offer you product placements and discuss details for the sponsorship with you.",
        imageUrl: "/assets/images/how-it-works-3.png",
      },
      {
        headline: "",
        text: "Sponsearly is your entry ticket into paid sponsoring cooperations. We know how hard it can be to get attention from Brands you actually like. With Sponsearly you can skip the line and early on access to Brand managers - without any further effort. Just sign up and verify your profile.",
        imageUrl: "/assets/images/how-it-works-4.png",
      },
    ],
  };

  return <ContentPage {...content} />;
};

export default HowItWorksCreator;
