import { FC, ReactNode } from "react";
import { Button } from "../shadcn/Button";

export interface PrimaryButtonProps {
  onClick: () => void;
  children: ReactNode; // This allows you to pass any React node as children
  disabled?: boolean;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      // className={`text-white font-bold py-2 px-4 rounded ${disabledClasses}`}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
