import { FC, ReactNode } from "react";

interface ContentSectionProps {
  headline: string;
  text: string;
  imageUrl?: string;
  smallImage?: boolean;
}

const ContentSection: FC<ContentSectionProps & { reverse?: boolean }> = ({
  headline,
  text,
  imageUrl,
  smallImage = false,
  reverse = false,
}) => {
  return (
    <div
      className={`flex flex-col mb-6 ${imageUrl ? "md:flex-row" : ""} ${
        reverse && imageUrl ? "md:flex-row-reverse" : ""
      }`}
    >
      <div className={`flex-1 ${imageUrl ? "md:px-4" : ""}`}>
        <h2 className="text-2xl font-bold mb-2">{headline}</h2>
        <p className="text-lg whitespace-pre-line">{text}</p>
      </div>
      {imageUrl && (
        <div
          className={`flex-1 ${
            smallImage ? "flex justify-center items-center" : ""
          }`}
        >
          <img
            src={imageUrl}
            alt={headline}
            className={`${smallImage ? "w-1/2 mx-auto" : "w-full"} h-auto`}
          />
        </div>
      )}
    </div>
  );
};

interface ContentPageProps {
  headline: string;
  imageUrl: string;
  contentSections: {
    headline: string;
    text: string;
    imageUrl?: string;
    smallImage?: boolean;
  }[];
  children?: ReactNode;
  smallImage?: boolean;
}

const ContentPage: FC<ContentPageProps> = ({
  headline,
  imageUrl,
  contentSections,
  children,
  smallImage = false,
}) => {
  return (
    <div className="max-w-6xl mx-auto my-8 p-4">
      <h1 className="text-4xl font-bold text-center mb-6">{headline}</h1>
      <img
        src={imageUrl}
        alt={headline}
        className={`${smallImage ? "w-1/2 mx-auto" : "w-full"} h-auto mb-6`}
      />
      {children}
      {contentSections.map((section, index) => (
        <ContentSection
          key={index}
          headline={section.headline}
          text={section.text}
          imageUrl={section.imageUrl}
          smallImage={section.smallImage}
          reverse={index % 2 !== 0}
        />
      ))}
    </div>
  );
};

export default ContentPage;
