import { FC } from "react";
import Chats from "../Chats/Chats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

interface ChatBoxProps {
  openChatPopup: (chatId: string) => void;
  isChatBoxOpen: boolean;
  setIsChatBoxOpen: (isOpen: boolean) => void;
}

const ChatBox: FC<ChatBoxProps> = ({
  openChatPopup,
  isChatBoxOpen,
  setIsChatBoxOpen,
}) => {
  const toggleChatBox = () => setIsChatBoxOpen(!isChatBoxOpen);

  return (
    <div className="fixed bottom-2 right-2 z-50">
      {!isChatBoxOpen && (
        <button
          onClick={toggleChatBox}
          className="flex items-center bg-primary text-white text-sm px-4 py-2 rounded shadow-lg hover:bg-primaryHover focus:outline-none focus:ring-2 focus:ring-blue-800"
        >
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          Messages
          <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
        </button>
      )}

      {isChatBoxOpen && (
        <div className="bg-backgroundPrimary border text-white border-backgroundSecondary rounded-lg shadow-lg w-96 h-96 flex flex-col">
          <button
            onClick={toggleChatBox}
            className="focus:outline-none w-full text-sm flex justify-between items-center px-4 pt-4 text-black"
          >
            <h1 className="text-m font-bold">Your Chats</h1>
            <FontAwesomeIcon icon={faChevronDown} />
          </button>

          <div className="overflow-auto flex-grow">
            <Chats openChatPopup={openChatPopup} isBox />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
