import { FC } from "react";
import { useLocation } from "react-router-dom";
import ChatBox from "../ChatBox/ChatBox";
import ChatPopup from "../ChatPopup/ChatPopup";
import { useAuth } from "../../../context/AuthContext";

interface ChatHandlerProps {
  isChatPopupOpen: boolean;
  activeChatId: string;
  isChatBoxOpen: boolean;
  setIsChatPopupOpen: (isOpen: boolean) => void;
  setIsChatBoxOpen: (isOpen: boolean) => void;
  openChatPopup: (chatId: string) => void;
}

const ChatHandler: FC<ChatHandlerProps> = ({
  isChatPopupOpen,
  activeChatId,
  isChatBoxOpen,
  setIsChatPopupOpen,
  setIsChatBoxOpen,
  openChatPopup,
}) => {
  const location = useLocation();
  const isChatRoute = location.pathname.includes("chat");
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser && !isChatRoute && (
        <ChatBox
          isChatBoxOpen={isChatBoxOpen}
          setIsChatBoxOpen={setIsChatBoxOpen}
          openChatPopup={openChatPopup}
        />
      )}
      {isChatPopupOpen && !isChatRoute && (
        <ChatPopup
          isChatBoxOpen={isChatBoxOpen}
          chatId={activeChatId}
          setIsChatPopupOpen={setIsChatPopupOpen}
        />
      )}
    </>
  );
};

export default ChatHandler;
