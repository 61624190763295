import { IMultiSelectElement } from "../../components/MultiSelect/MultiSelect";

export interface GroupedOption {
  label: string;
  options: IMultiSelectElement[];
  value: string;
}
export const babyOptions: IMultiSelectElement[] = [
  { value: "babydiaperswipes", label: "Baby Diapers & Wipes" },
  { value: "babyfeeding", label: "Baby Feeding" },
];

export const beautyOptions: IMultiSelectElement[] = [
  {
    value: "bathbody",
    label: "Bath & Body",
  },
  {
    value: "beautytoolsaccessories",
    label: "Beauty Tools & Accessories",
  },
  {
    value: "fragrances",
    label: "Fragrances",
  },
  {
    value: "makeup",
    label: "Makeup",
  },
  {
    value: "nailcare",
    label: "Nail Care",
  },
  {
    value: "premiumhaircare",
    label: "Premium Hair Care",
  },
  {
    value: "skincare",
    label: "Skin Care",
  },
  {
    value: "sunscreensuncare",
    label: "Sunscreen & Sun Care",
  },
];

export const clothingOptions: IMultiSelectElement[] = [
  {
    value: "clothingforbabies",
    label: "Clothing for Babies",
  },
  {
    value: "clothingforkids",
    label: "Clothing for Kids",
  },
  {
    value: "clothingformen",
    label: "Clothing for Men",
  },
  {
    value: "clothingforwomen",
    label: "Clothing for Women",
  },
  {
    value: "handbagswallets",
    label: "Handbags & Wallets",
  },
  {
    value: "luggage",
    label: "Luggage",
  },
  {
    value: "workwear",
    label: "Workwear",
  },
];

export const computerOptions: IMultiSelectElement[] = [
  {
    value: "hardwarecomponents",
    label: "Hardware / Components",
  },
  {
    value: "keyboard",
    label: "Keyboard",
  },
  {
    value: "mice",
    label: "Mice",
  },
  {
    value: "sound",
    label: "Sound",
  },
  {
    value: "chair",
    label: "Chair",
  },
  {
    value: "computersoftware",
    label: "Computer Software",
  },
  {
    value: "laptopsnotebookcomputers",
    label: "Laptops & Notebook Computers",
  },
  {
    value: "monitors",
    label: "Monitors",
  },
  {
    value: "routerswifiextendersmodems",
    label: "Routers, Wifi Extenders & Modems",
  },
];

export const healthOptions: IMultiSelectElement[] = [
  {
    value: "massageequipment",
    label: "Massage Equipment",
  },
  {
    value: "nutrition",
    label: "Nutrition",
  },
  {
    value: "personalcare",
    label: "Personal Care",
  },
  {
    value: "vitaminsherbalssupplements",
    label: "Vitamins, Herbals & Dietary Supplements",
  },
];

export const kitchenOptions: IMultiSelectElement[] = [
  {
    value: "cookwarebakeware",
    label: "Cookware & Bakeware",
  },
  {
    value: "cutlerykitchenknives",
    label: "Cutlery & Kitchen Knives",
  },
  {
    value: "homedecor",
    label: "Home Decor",
  },
  {
    value: "kitchenutensilsgadgets",
    label: "Kitchen Utensils & Gadgets",
  },
  {
    value: "tableware",
    label: "Tableware",
  },
];

export const homeImprovementOptions: IMultiSelectElement[] = [
  {
    value: "lightingceilinglightfixtures",
    label: "Lighting & Ceiling Light Fixtures",
  },
  {
    value: "toolshardware",
    label: "Tools & Hardware",
  },
];

export const toysOptions: IMultiSelectElement[] = [
  {
    value: "artscraftshobby",
    label: "Arts, Crafts & Hobby",
  },
  {
    value: "books",
    label: "Books",
  },
  {
    value: "buildingblockssets",
    label: "Building Blocks & Sets",
  },
  {
    value: "dollsactionfigures",
    label: "Dolls & Action Figures",
  },
  {
    value: "cosplay",
    label: "Cosplay",
  },
  {
    value: "outdoorwatertoys",
    label: "Outdoor & Water Toys",
  },
  {
    value: "puzzlescardsboardgames",
    label: "Puzzles, Cards & Board Games",
  },
  {
    value: "stuffedanimalsplushtoys",
    label: "Stuffed Animals & Plush Toys",
  },
];

export const groupedProductOptions: GroupedOption[] = [
  {
    label: "Baby",
    value: "baby",
    options: babyOptions,
  },
  {
    label: "Beauty",
    value: "beauty",
    options: beautyOptions,
  },
  {
    label: "Clothing",
    value: "clothing",
    options: clothingOptions,
  },
  {
    label: "Computers",
    value: "computers",
    options: computerOptions,
  },
  {
    label: "Health",
    value: "health",
    options: healthOptions,
  },
  {
    label: "Kitchen",
    value: "kitchen",
    options: kitchenOptions,
  },
  {
    label: "Home Improvement",
    value: "homeimprovement",
    options: homeImprovementOptions,
  },
  {
    label: "Toys",
    value: "toys",
    options: toysOptions,
  },
];
