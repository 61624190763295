const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p>Effective Date: 17.07.2024</p>

      <section className="mb-6">
        <p>
          Sponsearly ("we," "our," or "us") is committed to protecting and
          respecting your privacy. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you visit our
          website, sponsearly.com, and use our services. By using our website
          and services, you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          1. Information We Collect
        </h2>
        <p>
          We collect the following types of personal data through forms on our
          website:
        </p>
        <ul className="list-disc ml-5">
          <li>Names</li>
          <li>Email addresses</li>
          <li>Certain interests</li>
          <li>Addresses</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          2. How We Use Your Information
        </h2>
        <p>We use the information we collect from you to:</p>
        <ul className="list-disc ml-5">
          <li>Provide our services</li>
          <li>Facilitate communication between users</li>
          <li>Improve our website and services</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          3. Sharing Your Information
        </h2>
        <p>
          We only share your data with other users on the platform for the
          purpose of facilitating the services we provide. We do not sell, rent,
          or otherwise disclose your personal information to third parties for
          their marketing purposes.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">4. Your Rights</h2>
        <p>You have the right to:</p>
        <ul className="list-disc ml-5">
          <li>Access the personal data we hold about you</li>
          <li>Correct any inaccuracies in your personal data</li>
          <li>Request the deletion of your personal data</li>
        </ul>
        <p>
          To exercise these rights, you can delete your account, which will
          delete your personal data from our system. For any further assistance,
          please contact us at{" "}
          <a href="mailto:contact@sponsearly.com" className="text-blue-500">
            contact@sponsearly.com
          </a>
          .
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          5. Security of Your Information
        </h2>
        <p>
          We use a protected database to store your personal data and implement
          appropriate technical and organizational measures to safeguard your
          data against unauthorized access, disclosure, alteration, or
          destruction.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">6. Legal Compliance</h2>
        <p>
          We comply with the General Data Protection Regulation (GDPR) and other
          applicable data protection laws in Europe. This means we will:
        </p>
        <ul className="list-disc ml-5">
          <li>Process your data lawfully, fairly, and transparently</li>
          <li>
            Collect your data for specified, explicit, and legitimate purposes
          </li>
          <li>Limit data collection to what is necessary</li>
          <li>Ensure data is accurate and up-to-date</li>
          <li>Store your data only as long as necessary</li>
          <li>Secure your data appropriately</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">
          7. Changes to This Privacy Policy
        </h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and where appropriate, notified to you by
          email. Please review this policy periodically for any updates.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <address className="not-italic">
          Sponsearly
          <br />
          Nad Popelkou 1
          <br />
          15000 Praha, Czech Republic
          <br />
          Email:{" "}
          <a href="mailto:contact@sponsearly.com" className="text-blue-500">
            contact@sponsearly.com
          </a>
        </address>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
