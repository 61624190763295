// src/components/WelcomeModal.tsx
import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";

interface WelcomeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md mx-auto text-center">
        <h2 className="text-2xl font-semibold mb-4">Welcome to Sponsearly!</h2>
        <p className="mb-4">
          Thanks for joining us early! We're just getting started, so there
          might not be much to see yet. But by signing up now, you'll be among
          the first to benefit when we officially launch.
        </p>
        <p className="mb-6">
          Get in early and be ready for when the opportunities roll in!
        </p>
        <PrimaryButton onClick={onClose}>
          I want to be an early bird!
        </PrimaryButton>
      </div>
    </div>
  );
};

export default WelcomeModal;
