import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signup");
  };

  const backgroundStyle = {
    backgroundImage: "url(/assets/images/background-sponsearly.png)",
    backgroundSize: "auto", // This ensures the image covers the entire section
    backgroundPosition: "center", // This centers the image in the section
    backgroundRepeat: "no-repeat", // Ensures the image does not repeat
  };

  return (
    <div>
      <div className="text-center -mb-4">
        <h1 className="text-5xl font-bold mt-12 mb-4">Sponsearly</h1>
        <p className="text-xl my-4 ">Connecting Content Creators with Brands</p>
        <PrimaryButton onClick={handleGetStarted}>Get Started</PrimaryButton>
      </div>
      <div
        className="p-10 md:py-48 py-24"
        // className="text-center p-10 min-h-screen flex flex-col justify-center items-center"
        style={backgroundStyle}
      ></div>
    </div>
  );
};

export default HeroSection;
