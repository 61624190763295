import React from "react";

const About = () => {
  const content = {
    headline: "About Us",
    imageUrl: "/assets/images/aboutme.png", // Adjust the path as necessary
    smallImage: true,
    contentSections: [
      {
        headline: "",
        text: "Welcome to Sponsearly! I'm Peer, the founder of Sponsearly and a passionate gamer with a background in Business Informatics. After years of experience in tech and gaming, I decided to create a platform that bridges the gap between brands and content creators, making sponsorships more accessible and streamlined.",
      },
      {
        headline: "",
        text: "At Sponsearly, the goal is to make it easy for brands and early-stage content creators to connect. The mission is to simplify finding and securing sponsorships, using a powerful algorithm that identifies the best partnership opportunities and saves time for everyone involved.",
      },
      {
        headline: "",
        text: "One of the key tools in Sponsearly is a Twitch activity tracker, allowing brands to find content creators who are genuinely engaged in gaming. Whether you’re a brand looking to promote gaming accessories or a creator aiming to grow your channel, Sponsearly is here to support you.",
      },
      {
        headline: "",
        text: "Beyond work, I share a genuine love for video games, which adds to my enthusiasm for this industry. I'm excited to be part of your journey and look forward to helping you succeed in the evolving world of content creation and sponsorships.",
      },
    ],
  };

  return (
    <div className="max-w-6xl mx-auto my-8 p-4">
      <h1 className="text-4xl font-bold text-center mb-6">
        {content.headline}
      </h1>
      <div className="flex flex-col md:flex-row items-center mb-6">
        <div className="flex-1 flex justify-center md:justify-start mb-4 md:mb-0">
          <img
            src={content.imageUrl}
            alt={content.headline}
            className="max-w-full max-h-80 md:max-h-96 object-contain"
          />
        </div>
        <div className="flex-1 md:px-4">
          {content.contentSections.map((section, index) => (
            <div key={index} className="mb-6">
              <h2 className="text-2xl font-bold mb-2">{section.headline}</h2>
              <p className="text-lg">{section.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
