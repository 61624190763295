import { Fragment } from "react";
import ContentPage from "../../components/ContentPage/ContentPage";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";

const PricingForCreators = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/signup");
  };
  const content = {
    headline: "Pricing for Creators",
    imageUrl: "/assets/images/background-pricing.png", // Adjust the path as necessary
    contentSections: [
      {
        headline: "No Fees, Just Opportunities",
        text: "At Sponsearly, we believe in supporting creators at every stage of their journey. That's why signing up and using our platform is completely free for creators. We want to help you connect with brands and grow your audience without any barriers.",
      },
      {
        headline: "Unlimited Access to Sponsorships",
        text: "Once you’re signed up, you’ll have unlimited access to sponsorship opportunities with top brands. We connect you directly with brands looking for creators like you, making it easier to secure partnerships that align with your content and audience.",
      },
    ],
  };

  return (
    <Fragment>
      <ContentPage {...content} />
      <div className="flex justify-center align-middle mb-10">
        <PrimaryButton onClick={handleGetStarted}>Get Started</PrimaryButton>
      </div>
    </Fragment>
  );
};

export default PricingForCreators;
