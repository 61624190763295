import { FC, ReactNode } from "react";
import { Button } from "../shadcn/Button";

export interface SecondaryButtonProps {
  onClick: () => void;
  children: ReactNode; // This allows you to pass any React node as children
}

const SecondaryButton: FC<SecondaryButtonProps> = ({ onClick, children }) => {
  return (
    <Button
      onClick={onClick}
      variant={"secondary"}
      // className="bg-secondary hover:bg-secondaryHover text-white font-bold py-2 px-4 rounded"
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
