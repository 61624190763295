import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { callTwitchExchangeFunction } from "../../../API/callTwitchExchangeFunction";
import { fetchTwitchUserData } from "../../../API/fetchTwitchUserData"; // Assuming you have this function
import { db } from "../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "../../../context/AuthContext";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/shadcn/Dialog";

const TwitchAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      callTwitchExchangeFunction(code)
        .then(async (response: any) => {
          const accessToken = response?.access_token;
          const twitchUserData = await fetchTwitchUserData(accessToken);

          if (currentUser?.uid) {
            // Fetch current user's data
            const userRef = doc(db, "users", currentUser.uid);
            const userDoc = await getDoc(userRef);
            let currentUserData = null;
            if (userDoc.exists()) {
              currentUserData = userDoc.data();
            } else {
              console.log("No such user document!");
            }

            // Check if another user has the same twitchName and isTwitchUserAuthenticated == true
            const usersRef = collection(db, "users");
            const q = query(
              usersRef,
              where("twitchName", "==", twitchUserData.login),
              where("isTwitchUserAuthenticated", "==", true)
            );

            const querySnapshot = await getDocs(q);

            let twitchNameTaken = false;

            querySnapshot.forEach((doc) => {
              if (doc.id !== currentUser.uid) {
                twitchNameTaken = true;
              }
            });

            if (twitchNameTaken) {
              // Another user has already authenticated with this Twitch account
              // Set isTwitchUserAuthenticated to false for the current user
              await updateDoc(userRef, {
                isTwitchUserAuthenticated: false,
              });

              // Open the AlertDialog
              setIsDialogOpen(true);

              // Since the Twitch name is taken, you might want to handle additional logic here
            } else {
              // No other user has this Twitch account
              await updateDoc(userRef, {
                twitchName: twitchUserData.login,
                isTwitchUserAuthenticated: true,
              });

              // Now, call updateInviterInvitedUserStatus after updating the current user's document
              if (
                currentUserData?.inviterName &&
                currentUserData?.displayName
              ) {
                // Update inviter's invitedUsers data
                await updateInviterInvitedUserStatus(
                  currentUserData.inviterName,
                  currentUserData.displayName,
                  twitchUserData.login
                );
              }

              navigate("/profile");
            }
          }
        })
        .catch((error) => {
          console.error("Twitch exchange error:", error);
          // Handle errors appropriately
          navigate("/profile");
        });
    } else {
      navigate("/"); // Redirect back to a safe route
    }
  }, [location, navigate, currentUser]);

  // Function to update inviter's invitedUsers array
  const updateInviterInvitedUserStatus = async (
    inviterName: string,
    invitedUserName: string,
    twitchName: string
  ) => {
    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("displayName", "==", inviterName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const inviterDocRef = querySnapshot.docs[0].ref;
        const inviterDocData = querySnapshot.docs[0].data();

        const invitedUsers = inviterDocData.invitedUsers || [];

        // Find the index of the invitedUser
        const index = invitedUsers.findIndex(
          (user: any) => user.displayName === invitedUserName
        );

        if (index !== -1) {
          // Update the isTwitchAuthenticated and currentDate
          invitedUsers[index] = {
            ...invitedUsers[index],
            isTwitchAuthenticated: true,
            currentDate: new Date(),
            twitchName: twitchName,
          };

          await updateDoc(inviterDocRef, { invitedUsers });

          return true;
        } else {
          console.log("Invited user not found in inviter's invitedUsers array");
          return false;
        }
      } else {
        console.log("Inviter not found");
        return false;
      }
    } catch (error) {
      console.error("Error updating inviter data:", error);
      return false;
    }
  };

  return (
    <>
      <LoadingSpinner />
      <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Account Conflict Detected</AlertDialogTitle>
            <AlertDialogDescription>
              This Twitch account is already associated with another user. If
              this is an error, please contact support.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                setIsDialogOpen(false);
                navigate("/profile"); // Navigate after closing the dialog
              }}
            >
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default TwitchAuth;
