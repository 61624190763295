// src/components/CreatorProfileView.js
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, realTimeDb } from "../../firebaseConfig";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useAuth } from "../../context/AuthContext";
// import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

// import {
//   StreamChartData,
//   chartOptions,
//   initialChartData,
//   prepareChartData,
// } from "./utils";
// import TwitchTrackerWeekly from "./components/TwitchTrackerWeekly";
import { IUser } from "../../interfaces/IUser";
import { ref, push, set, get } from "firebase/database";
import ProfileBannerDisplay from "../../components/ProfileBannerDisplay/ProfileBannerDisplay";
import TwitchMetrics from "./components/TwitchMetrics";
import { ILastDays } from "../../interfaces/ITwitch";
import SecondaryButton from "src/components/Buttons/SecondaryButton";
import { Badge } from "src/components/shadcn/Badge";

const ProfileView = () => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );

  const { creatorId } = useParams();
  const [creator, setCreator] = useState<IUser | undefined>();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [twitchMetrics, setTwitchMetrics] = useState<ILastDays | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState("last 7 days"); // default to 'week'

  // const [chartData, setChartData] = useState<StreamChartData>(initialChartData);

  useEffect(() => {
    const fetchCreator = async () => {
      if (creatorId) {
        const docRef = doc(db, "users", creatorId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data() as IUser;
          setCreator(userData);
          setTwitchMetrics(userData?.twitchMetrics?.lastSevenDays);
        } else {
          console.log("No such document!");
        }
      }
    };
    fetchCreator();
    if (creatorId) {
      // updateTwitchData();
    }

    setSelectedPeriod("last 7 days");
  }, [creatorId]);

  useEffect(() => {
    if (selectedPeriod === "last 7 days") {
      setTwitchMetrics(creator?.twitchMetrics?.lastSevenDays);
    }
    if (selectedPeriod === "last 30 days") {
      setTwitchMetrics(creator?.twitchMetrics?.last30Days);
    }

    if (selectedPeriod === "last 90 days") {
      setTwitchMetrics(creator?.twitchMetrics?.last90Days);
    }
  }, [selectedPeriod]);

  // useEffect(() => {
  // if (creator && creator.twitch && creator.twitch.twitchStreams) {
  // const preparedData = prepareChartData(creator.twitch.twitchStreams);
  // setChartData(preparedData);
  // }
  // Assuming twitchTracker is already sorted by date
  // if (creator && creator.twitchTracker && creator.twitchTracker.length > 0) {
  //   // Directly take the last element of the array as the most recent entry
  //   const latestTwitchTrackerEntry =
  //     creator.twitchTracker[creator.twitchTracker.length - 1];
  // }
  // }, [creator]);

  const startChat = async () => {
    if (!creatorId || !currentUser) return;

    // Check if a chat already exists in 'chats' node
    const chatsRef = ref(realTimeDb, "chats");
    const chatsSnapshot = await get(chatsRef);
    let existingChatId = null;

    if (chatsSnapshot.exists()) {
      const chats = chatsSnapshot.val();
      existingChatId = Object.keys(chats).find(
        (chatId) =>
          chats[chatId].participants[currentUser.uid] &&
          chats[chatId].participants[creatorId]
      );
    }

    if (existingChatId) {
      // If the chat already exists, create/update userChats entry for the current user
      set(ref(realTimeDb, `userChats/${currentUser.uid}/${existingChatId}`), {
        [creatorId]: true,
      });
      navigate(`/chat/${existingChatId}`);
    } else {
      // Create a new chat
      const newChatRef = push(ref(realTimeDb, "chats"));
      set(newChatRef, {
        participants: {
          [currentUser.uid]: true,
          [creatorId]: true,
        },
      });

      // Update userChats for both users
      set(ref(realTimeDb, `userChats/${currentUser.uid}/${newChatRef.key}`), {
        [creatorId]: true,
      });
      set(ref(realTimeDb, `userChats/${creatorId}/${newChatRef.key}`), {
        [currentUser.uid]: true,
      });

      navigate(`/chat/${newChatRef.key}`);
    }
  };

  // Target date is 20th August 2024
  const targetDate = new Date("2024-08-20");

  // Get today's date
  const today = new Date();

  // Calculate the difference in days between today and the target date
  const diffInDays = Math.floor(
    (today.getTime() - targetDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  // Define periods and conditionally include "last 90 days" based on the date difference
  const periods = ["last 7 days", "last 30 days", "last 90 days"].filter(
    (period) => period !== "last 90 days" || diffInDays >= 90
  );

  // Function to handle tab click and set selected period
  const handleTabClick = (period: string) => {
    setSelectedPeriod(period);
  };
  return (
    <div className="container mx-auto p-4 overflow-x-hidden">
      {!creator ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <ProfileBannerDisplay userId={creatorId ?? ""} />

          {/* CREATOR */}
          {creator?.role === "creator" && (
            <Fragment>
              <h2 className=" mb-4 flex justify-between">
                <div className="text-2xl font-bold">
                  User: {creator?.displayName}
                  {/* <p>
                    Twitch Channel:{" "}
                    {creator.twitchName && (
                      <a
                        href={`https://www.twitch.tv/${creator.twitchName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {creator?.twitchName}
                      </a>
                    )}
                  </p> */}
                  {!creator.isTwitchUserAuthenticated && (
                    <p className="text-accent">
                      Attention! The user did not confirm ownership over the
                      twitch channel!
                    </p>
                  )}
                </div>
                {creatorId !== currentUser?.uid && (
                  <div className="text-md flex gap-1">
                    <PrimaryButton onClick={startChat}>
                      Start Chat
                    </PrimaryButton>
                    {creator.twitchName && (
                      <PrimaryButton onClick={() => {}}>
                        <a
                          href={`https://www.twitch.tv/${creator.twitchName}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          TWITCH {creator?.twitchName}
                        </a>
                      </PrimaryButton>
                    )}
                  </div>
                )}
              </h2>

              <h3 className="text-xl font-semibold">
                Latest Twitch Analytics Data
              </h3>
              <div className="flex space-x-4 mb-4">
                {periods.map((period) =>
                  selectedPeriod !== period ? (
                    <PrimaryButton
                      key={period}
                      onClick={() => handleTabClick(period)}
                    >
                      {period.toUpperCase()}
                    </PrimaryButton>
                  ) : (
                    <SecondaryButton
                      key={period}
                      onClick={() => handleTabClick(period)}
                    >
                      {period.toUpperCase()}
                    </SecondaryButton>
                  )
                )}
              </div>
              {twitchMetrics && (
                <div>
                  <TwitchMetrics
                    twitchMetrics={twitchMetrics}
                    twitchName={creator.twitchName}
                  />
                </div>
              )}

              {/* {chartData.datasets[0].data.length > 0 && (
                <div className="my-4">
                  <h3 className="text-xl font-semibold">Stream Analysis</h3>
                  <Line options={chartOptions} data={chartData} />
                </div>
              )} */}
              {creator.about && (
                <div className="mb-4">
                  <div>
                    <b>About me:</b>
                  </div>
                  <div>{creator.about}</div>
                </div>
              )}
            </Fragment>
          )}

          {creator.role === "brand" && (
            <Fragment>
              <h2 className=" mb-4 flex justify-between">
                <div className="text-2xl font-bold">
                  User: {creator?.displayName}
                  <p>Company: {creator?.companyName} </p>
                  {creator?.website && (
                    <p>
                      <a
                        href={
                          /^(http:\/\/|https:\/\/)/.test(creator.website)
                            ? creator.website
                            : `https://${creator.website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="truncate" // Use 'truncate' to handle long URLs
                      >
                        {creator.website}
                      </a>
                    </p>
                  )}
                </div>
                {creatorId !== currentUser?.uid && (
                  <div className="text-md">
                    <PrimaryButton onClick={startChat}>
                      Start Chat
                    </PrimaryButton>
                  </div>
                )}
              </h2>
              {creator.about && (
                <div className="mb-4">
                  <div>
                    <b>About us:</b>
                  </div>
                  <div>{creator.about}</div>
                </div>
              )}
            </Fragment>
          )}

          <div className="flex flex-wrap -mx-3 mb-4">
            {creator?.favoriteCategories &&
              creator.favoriteCategories.length > 0 && (
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                  <div className="mb-4">
                    <h3 className="text-xl font-semibold">
                      {creator.role === "creator" && "Open "}Categories
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {creator?.favoriteCategories?.map((category, index) => (
                        <Badge
                          key={index}
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(
                              `${
                                creator.role === "creator"
                                  ? "/fresh-talents"
                                  : "/brands"
                              }`,
                              {
                                state: {
                                  initialFilter: {
                                    favoriteCategories: category.label,
                                  },
                                },
                              }
                            )
                          }
                        >
                          {category.label}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </div>
              )}

            {creator?.favoriteProducts &&
              creator.favoriteProducts.length > 0 && (
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <div className="mb-4">
                    <h3 className="text-xl font-semibold">
                      {creator.role === "creator" && "Open "}Products
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {creator?.favoriteProducts?.map((product, index) => (
                        <Badge
                          key={index}
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(
                              `${
                                creator.role === "creator"
                                  ? "/fresh-talents"
                                  : "/brands"
                              }`,
                              {
                                state: {
                                  initialFilter: {
                                    favoriteProducts: product.label,
                                  },
                                },
                              }
                            )
                          }
                        >
                          {product.label}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            {creator?.productLinks && creator.productLinks.length > 0 && (
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <div className="mb-4">
                  <h3 className="text-xl font-semibold">Product Links</h3>
                  <div className="flex flex-wrap gap-2">
                    {creator?.productLinks?.map((link, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <Badge key={index} className="cursor-pointer">
                          <a
                            href={
                              /^(http:\/\/|https:\/\/)/.test(link)
                                ? link
                                : `https://${link}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white truncate" // Use 'truncate' to handle long URLs
                          >
                            {link}
                          </a>
                        </Badge>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <h3 className="text-xl font-semibold">Audience:</h3>
            {creator?.audience?.map((audience, index) => (
              <Badge
                key={index}
                className=" cursor-pointer"
                onClick={() =>
                  navigate(
                    `${
                      creator.role === "creator" ? "/fresh-talents" : "/brands"
                    }`,
                    {
                      state: {
                        initialFilter: {
                          audience: audience.label,
                        },
                      },
                    }
                  )
                }
              >
                {audience.label}
              </Badge>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ProfileView;
