import { FC, useEffect, useState } from "react";
import { doc, getDoc } from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { db, realTimeDb } from "../../../firebaseConfig";
import LoadingSpinner from "../../../components/Loading/LoadingSpinner";
import { formatDateTimeString } from "../../../utils/dateUtils";
import { get, ref } from "firebase/database";
import ProfilePicture from "../../../components/ProfilePicture/ProfilePicture";

interface ChatsProps {
  isBox?: boolean;
  openChatPopup: (chatId: string) => void;
}

const Chats: FC<ChatsProps> = ({ isBox = false, openChatPopup }) => {
  const [chats, setChats] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchChats = async () => {
      if (!currentUser) return;

      setIsLoading(true);

      try {
        const userChatsRef = ref(realTimeDb, `userChats/${currentUser.uid}`);
        const snapshot = await get(userChatsRef);

        if (snapshot.exists()) {
          const chatIds = Object.keys(snapshot.val());
          const chatsData = await Promise.all(
            chatIds.map(async (chatId) => {
              const chatRef = ref(realTimeDb, `chats/${chatId}`);
              const chatSnapshot = await get(chatRef);
              if (!chatSnapshot.exists()) return null;

              const chatData = chatSnapshot.val();
              const participantIds = Object.keys(chatData.participants).filter(
                (id) => id !== currentUser.uid
              );

              const otherParticipantId = participantIds[0];

              const participantNames = await Promise.all(
                participantIds.map(async (id) => {
                  const userRef = doc(db, "users", id);
                  const userSnap = await getDoc(userRef);
                  return userSnap.exists()
                    ? userSnap.data().displayName || "Unknown"
                    : "Unknown";
                })
              );

              return {
                id: chatId,
                participantNames: participantNames.join(", "),
                participantId: otherParticipantId, // Add this line
                lastMessage: chatData.lastMessage,
                lastMessageTimestamp: chatData.lastMessageTimestamp,
                unreadBy: chatData.unreadBy,
              };
            })
          );

          setChats(
            chatsData
              .filter((chat) => chat) // Filter out nulls and ensure timestamp exists
              .sort((a, b) => {
                // Convert ISO date strings to Date objects and then to timestamps
                const timestampA = new Date(a?.lastMessageTimestamp).getTime();
                const timestampB = new Date(b?.lastMessageTimestamp).getTime();

                return timestampB - timestampA;
              })
          );
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChats();
  }, [currentUser]);

  const handleChatClick = (chatId: string) => {
    if (isBox) {
      openChatPopup(chatId);
    } else {
      navigate(`/chat/${chatId}`);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={`container text-white mx-auto px-4 ${!isBox && "py-4"}`}>
      {!isBox && (
        <h1 className="text-2xl font-bold mb-4 text-black">Your Chats</h1>
      )}
      {chats.length > 0 ? (
        <ul>
          {chats.map((chat) => (
            <li
              key={chat.id}
              className="flex items-center p-4 bg-primary my-2 rounded-lg cursor-pointer hover:bg-primaryHover hover:shadow-lg relative"
              onClick={() => handleChatClick(chat.id)}
            >
              <div className="flex-none">
                <ProfilePicture userId={chat.participantId} size="3" />
              </div>
              <div className="flex-grow ml-4">
                <div>{chat.participantNames}</div>
                <p className="text-sm mt-2 text-gray-400 flex justify-between">
                  <span>{chat.lastMessage} </span>
                  <span>{formatDateTimeString(chat.lastMessageTimestamp)}</span>
                </p>
              </div>
              {chat.unreadBy === currentUser?.uid && (
                <span className="absolute top-4 right-2 bg-primary text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-full">
                  New
                </span>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className= "text-secondaryText">No chats available.</p>
      )}
    </div>
  );
};

export default Chats;
