const Imprint = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Impressum</h1>

      <p>
        Peer Vincent Stubbe
        <br />
        Nad Popelkou 1<br />
        15000 Prag
      </p>
      <br />

      <b>Kontakt</b>
      <p>
        Telefon: +49 (0) 179 4377 955
        <br />
        E-Mail: info@sponsearly.com
      </p>
      <br />

      <b>Umsatzsteuer-ID</b>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
        Umsatzsteuergesetz:
        <br />
        CZ9309041489
      </p>
      <br />

      <b>Redaktionell verantwortlich</b>
      <p>
        Peer Vincent Stubbe
        <br />
        Nad Popelkou 1<br />
        15000
        <br />
        Prag
      </p>
      <br />

      <b>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </b>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Imprint;
