import "./App.css";
import "animate.css";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/Header/Header";
import SponsRouter from "./components/SponsRouter/SponsRouter";
import { AuthProvider } from "./context/AuthContext";
import { useState } from "react";
import ChatHandler from "./modules/Chat/ChatHandler/ChatHandler";
import FooterHandler from "./components/Footer/FooterHandler";
import { ToastProvider } from "./context/ToastContext";

const App = () => {
  // State to manage whether the chat popup is open and which chat is active
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(false);
  const [activeChatId, setActiveChatId] = useState("");

  const openChatPopup = (chatId: string) => {
    setActiveChatId(chatId);
    setIsChatPopupOpen(true);
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastProvider>
          <div className="flex flex-col min-h-screen bg-backgroundPrimary">
            <Header />
            <main className="flex-grow">
              <SponsRouter openChatPopup={openChatPopup} />
            </main>
            <FooterHandler />
          </div>
          <ChatHandler
            isChatPopupOpen={isChatPopupOpen}
            activeChatId={activeChatId}
            isChatBoxOpen={isChatBoxOpen}
            setIsChatBoxOpen={setIsChatBoxOpen}
            setIsChatPopupOpen={setIsChatPopupOpen}
            openChatPopup={openChatPopup}
          />
        </ToastProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
