// src/components/SignUp.js
import { useEffect, useState } from "react";
import { auth, db } from "../../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { doc, setDoc, increment } from "firebase/firestore";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCreator, setIsCreator] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // State to hold inviterName
  const [inviterName, setInviterName] = useState("");

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const inviterNameFromParams = searchParams.get("inviter") || "";

  // Handle inviterName from URL params and localStorage
  useEffect(() => {
    if (inviterNameFromParams) {
      // Save inviterName to state and localStorage
      setInviterName(inviterNameFromParams);
      localStorage.setItem("inviterName", inviterNameFromParams);
    } else {
      // If no inviterName in params, check localStorage
      const inviterNameFromStorage = localStorage.getItem("inviterName") || "";
      setInviterName(inviterNameFromStorage);
    }
  }, [inviterNameFromParams]);

  // Add useEffect to track inviterName visits
  useEffect(() => {
    if (inviterName) {
      const updateReferrerStatistics = async () => {
        try {
          const referrerDocRef = doc(db, "referrerStatistics", inviterName);

          // Atomically increment the count field
          await setDoc(
            referrerDocRef,
            { count: increment(1) },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating referrer statistics:", error);
        }
      };
      updateReferrerStatistics();
    }
  }, [inviterName]);

  const signUp = async () => {
    try {
      setIsLoading(true);

      // Re-check inviterName before sign up
      let inviterNameToUse = inviterName;
      if (!inviterNameToUse) {
        inviterNameToUse = localStorage.getItem("inviterName") || "";
      }

      // Get the firstSiteParamVisited from localStorage
      const siteParamVisited =
        localStorage.getItem("firstSiteParamVisited") || "";

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await sendEmailVerification(userCredential.user);

      // Define initial user data based on the type
      const initialUserData = {
        role: isCreator ? "creator" : "brand",
        inviterName: inviterNameToUse || null,
        siteParamVisited: siteParamVisited || null,
        // Add other default fields as necessary
      };

      // Create a user document in Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), initialUserData);

      // Update signUpStatistics
      const today = new Date();
      const dateString = `${today.getFullYear()}_${String(
        today.getMonth() + 1
      ).padStart(2, "0")}_${String(today.getDate()).padStart(2, "0")}`; // Format: YYYY_MM_DD

      // Update statistics for the siteParamVisited
      if (siteParamVisited) {
        // Reference to the document for the siteParamVisited
        const siteDocRef = doc(db, "signUpStatistics", siteParamVisited);

        // Update the count for the date field directly
        await setDoc(
          siteDocRef,
          {
            [dateString]: increment(1),
            totalCount: increment(1),
          },
          { merge: true }
        );
      }

      // Update overall sign-up statistics
      const overallDocRef = doc(db, "signUpStatistics", "overall");

      // Update the count for the date field directly
      await setDoc(
        overallDocRef,
        {
          [dateString]: increment(1),
        },
        { merge: true }
      );

      // Optionally, clear 'firstSiteParamVisited' from localStorage
      // localStorage.removeItem('firstSiteParamVisited');

      // Clear inviterName from localStorage
      localStorage.removeItem("inviterName");

      // Navigate to the appropriate profile creation page
      if (isCreator) {
        navigate("/user-profile-creation");
      } else {
        navigate("/brand-profile-creation");
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container m-auto mt-4 p-4 flex flex-row items-center justify-center ">
      {isLoading && <LoadingSpinner />}

      <div className="w-1/2 bg-backgroundSecondary rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4">Sign Up</h2>
        {error && <p className="text-red-500">{error}</p>}
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-6 flex justify-center">
          <label className="flex items-center cursor-pointer">
            <span className="mr-3 font-medium">Creator Account</span>
            {/* Toggle switch container */}
            <div className="relative">
              {/* Hidden checkbox */}
              <input
                type="checkbox"
                className="hidden"
                onChange={() => setIsCreator(!isCreator)}
              />
              {/* Switch background */}
              <div
                className={`block bg-gray-200 h-6 w-11 rounded-full transition ${
                  !isCreator ? "bg-secondary" : "bg-primary"
                }`}
              ></div>
              {/* Switch handle */}
              <div
                className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition transform ${
                  !isCreator ? "translate-x-5" : ""
                }`}
              ></div>
            </div>
            <span className="ml-3 font-medium">Brand Account</span>
          </label>
        </div>

        <div className="flex justify-end mt-4">
          {isCreator ? (
            <PrimaryButton onClick={signUp}>Sign Up as Creator</PrimaryButton>
          ) : (
            <SecondaryButton onClick={signUp}>Sign Up as Brand</SecondaryButton>
          )}
        </div>
      </div>
      <div className="w-1/2 p-4">
        <h1 className="text-xl font-bold mb-4">Why Sign Up?</h1>
        <p className="mb-4">
          Exclusive Access to Brand Deals: Be the first to know about new
          sponsorship opportunities tailored to your content.
        </p>
        <p>
          Grow Your Audience: Partner with brands that align with your values
          and attract more viewers to your channel.
        </p>
      </div>
    </div>
  );
};

export default SignUp;
